@import '../../scss/mixins';

.shop-detailed-header {
    align-items: center;

    @include mobile {
        display: none;
    }

    @include tablet {
        display: flex;
        justify-content: center;
        margin-bottom: 11px;
    }

    @include laptop {
        height: 40px;
        justify-content: unset;
        margin-bottom: 32px;
    }

    @include desktop {
        height: 61px;
        margin-bottom: 23px;
    }

    button {
        height: 100%;

        @include mobile {
            display: none;
        }

        @include laptop {
            display: unset;
            font-size: 16px;
        }

        @include desktop {
            font-size: 18px;
        }
    }

    p {
        font-weight: 700;

        color: var(--color-raspberry-sorbet);

        @include tablet {
            display: unset;
            font-size: 28px;
        }

        @include laptop {
            display: unset;
            font-size: 30px;
            margin-left: 30px;
        }

        @include desktop {
            font-size: 36px;
            margin-left: 43px;
        }
    }
}