@import "../../scss/mixins";

.header-image {
  height: 180px;
  width: 100%;
  background-color: #5992de;

  background-image: url("../../assets/home-hero/image.jpg");
  background-size: cover;
  position: relative;
  margin-bottom: -68px;

  .sticker {
    display: block;
    width: 69px;
    border-radius: 50%;
    height: 69px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(135deg, #ff005e 16.7%, #dec400 83.95%);
    /* Drop shadow heavy */
    font-weight: bold;
    font-size: 9px;
    line-height: 11px;
    text-align: center;
    letter-spacing: -0.01em;
    color: #ffffff;
    transform: rotate(-4.93deg);
    letter-spacing: -0.03em;
    box-shadow: 0px 5px 30px rgba(0, 0, 0, 0.5);
    position: absolute;
    right: 30px;
    bottom: -10px;
    z-index: 65;
    span {
      max-width: 90%;
      line-height: 20px;
      @include mobile {
        line-height: 20px;
      }
      @include mobileLandscape {
        line-height: 1.2;
      }
      @include tablet {
        line-height: 30px;
      }
      @include laptop {
        line-height: 30px;
      }
      @include desktop {
        line-height: 45px;
      }
    }

    @include mobile {
      width: 115px;
      border-radius: 50%;
      height: 115px;
      font-size: 14px;
      line-height: 11px;
      letter-spacing: -0.01em;
      letter-spacing: -0.03em;
      position: absolute;
      right: 10px;
      bottom: -10px;
      z-index: 65;
    }

    @include mobileLandscape {
      width: 130px;
      border-radius: 50%;
      height: 130px;
      font-size: 16px;
      letter-spacing: -0.01em;
      letter-spacing: -0.03em;
      right: 20px;
      bottom: -10px;
      z-index: 65;
    }

    @include tablet {
      width: 187px;
      border-radius: 50%;
      height: 187px;
      font-size: 24px;
      line-height: 11px;
      letter-spacing: -0.01em;
      letter-spacing: -0.03em;
      right: 30px;
      bottom: -10px;
      z-index: 65;
    }

    @include laptop {
      width: 187px;
      border-radius: 50%;
      height: 187px;
      font-size: 24px;
      line-height: 38px;
      letter-spacing: -0.03em;
      right: 30px;
      bottom: -20px;
      z-index: 65;
    }

    @include desktop {
      width: 302px;
      border-radius: 50%;
      height: 302px;
      /* Drop shadow heavy */
      font-size: 36px;
      line-height: 45px;
      transform: rotate(-4.93deg);
      letter-spacing: -0.01em;
      box-shadow: 0px 5px 30px rgba(0, 0, 0, 0.5);
      position: absolute;
      right: 30px;
      bottom: 140px;
      z-index: 65;
    }
  }

  @include tablet {
    height: 287px;
    margin-bottom: 0;
  }

  @include laptop {
    height: 292px;
    margin-bottom: -116px;
  }

  @include desktop {
    height: 548px;
    margin-bottom: -280px;
  }
}
