@import '../../scss/mixins';

$join-localo-form: '.join-localo-form';

.join-localo-form {
    position: relative;
    padding: 20px 0;
    
    @include tablet() {
        padding: 30px 0;
    }
    
    @include laptop() {
        overflow: hidden;
        padding: 40px 0;
    }

    @include desktop() {
        padding: 90px 0;
    }

    &__title {
        color: var(--color-mint-green);
        font-weight: bold;
        font-size: 21px;
        line-height: 26px;
        text-align: center;
        padding: 0 35px;

        @include tablet() {
            font-size: 28px;
            line-height: 35px;
            margin-bottom: 25px;
            padding: 0;
        }

        @include laptop() {
            text-align: left;
            max-width: 515px;
            font-size: 40px;
            line-height: 50px;
            margin-bottom: 35px;
        }
        
        @include desktop() {
            max-width: 800px;
            font-size: 35px;
            line-height: 45px;
            margin-bottom: 65px;
        }
    }

    &__form {
        max-width: 345px;
        margin: 200px auto 0;

        @include laptop() {
            max-width: 625px;
            margin: 0;
        }
    }

    &__control {
        display: block;
        height: 40px;
        width: 100%;
        padding: 8px 12px;
        font-size: 16px;
        line-height: 1.5;
        font-weight: 500;
        margin: 0;
        font-family: inherit;
        color: var(--color-mid-charcoal);
        background-color: #fff;
        border: 3px solid var(--color-mint-green);
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        border-radius: 0;
        box-sizing: border-box;
        text-align: center;

        &:focus {
            outline: none;
            box-shadow: none;
        }

        &::placeholder {
            color: var(--color-mid-charcoal);
            opacity: 1;
        }
        
        &:-ms-input-placeholder {
            color: var(--color-mid-charcoal);
        }
        
        &::-ms-input-placeholder {
            color: var(--color-mid-charcoal);
        }

        @include laptop() {
            font-size: 30px;
            height: 70px;
            text-align: left;
            padding: 10px 20px;
        }
    }

    textarea {
        &#{$join-localo-form}__control {
            resize: none;
            height: 86px;
            text-align: left;

            @include laptop() {
                height: 200px;
            }
        }
    }

    &__field {
        margin-bottom: 10px;

        @include laptop() {
            margin-bottom: 30px;
        }

        .mySelectDefault {
            margin-right: 0;
            max-width: 100%;
            
            .fieldset {
              margin-top: 0;
              box-sizing: border-box;
              border: 3px solid #8ddc73;
              padding: 0;
              box-sizing: border-box;

              .selectedDisplay {
                font-size: 16px;
                color: var(--color-mid-charcoal);
                font-weight: 500;
                text-align: center;
                width: 100%;

                @include laptop() {
                    font-size: 30px;
                    text-align: left;
                }
              }
    
              .select_option {
                @include mobile {
                  padding: 0.8em 0.5em;
                }
                @include tablet {
                  padding: 0.5em 0 0.5em 0.5em;
                }
              }
            }
    
            .mainSectionWrapper {
              min-height: 34px;
              cursor: pointer;

              @include laptop() {
                  min-height: 64px;
              }
    
              .cancelContainer {
                display: none;
              }
    
              .downArrowContainer {
                  
                  @include laptop() {
                    margin-right: 20px;
                    transform: scale(1.15);
                }

                .downArrowContent {
                  & > svg {
                    fill: var(--color-mint-green);
                    border: 3px solid var(--color-mint-green);
                    border-radius: 50%;
                    box-sizing: border-box;
                  }
                }
              }
            }

            &:not(.valueSelected),
            &.selectOpen {
              &:before {
                pointer-events: none;
                position: absolute;
                z-index: 999;
                top: 50%;
                left: 0;
                right: 0;
                content: "Shop type";
                transform: translateY(-50%);
                font-size: 16px;
                font-weight: 500;
                letter-spacing: -0.03em;
                color: var(--color-mid-charcoal);
                text-align: center;

                @include laptop() {
                    font-size: 30px;
                    text-align: left;
                    left: 20px;
                }
              }
            }

            &.selectOpen {
                &.valueSelected {
                    &::before {
                        content: none;
                    }
                }
            } 
        }
    }

    &__submit {
        height: 50px;
        padding: 0 50px;
        background-color: var(--color-mint-green);
        color: #FFFFFF;
        font-size: 18px;
        margin: 0;
        font-family: inherit;
        display: block;
        font-weight: bold;
        text-align: center;
        cursor: pointer;
        border: 3px solid transparent;
        border-radius: 0;
        transition: all .15s ease-in-out;
        max-width: 160px;
        margin: 20px auto 0;

        &:hover {
            color: var(--color-mint-green);
            border-color: var(--color-mint-green);
            background-color: #FFFFFF;
        }

        @include laptop() {
            height: 80px;
            padding: 0 40px;
            font-size: 32px;
            max-width: unset;
            margin: 0;
        }

        @include desktop() {
            height: 100px;
            padding: 0 50px;
        }
    }

    &__col1-on-laptop {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        @include laptop() {
            flex-direction: column;
        }
        
        #{$join-localo-form}__field {
            flex: 0 0 calc(50% - 5px);
            max-width: calc(50% - 5px);

            @include laptop() {
                flex: 0 0 100%;
                max-width: 100%;
            }
        }
    }

    &__col2 {
        display: flex;
        justify-content: space-between;

        #{$join-localo-form}__field {
            flex: 0 0 calc(50% - 5px);
            max-width: calc(50% - 5px);

            @include laptop() {
                flex: 0 0 calc(50% - 15px);
                max-width: calc(50% - 15px);
            }
        }
    }

    &__infographic {
        position: absolute;
        pointer-events: none;
        z-index: -1;
        right: 0;
        left: 0;
        bottom: -105px;
        margin: 0 auto;
        width: 356px;
        height: 673px;
        
        img {
            max-width: 100%;
            vertical-align: middle;
        }

        @include laptop() {
            right: -130px;
            bottom: -35px;
            left: auto;
            margin: 0;
            width: auto;
            height: auto;
        }

        @include desktop() {
            right: 10px;
            left: auto;
            bottom: -170px;
        }
    }

    &__fruit-right {
        background-image: url('../../assets/join-localo/fruit-right.svg');
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        display: block;
        position: absolute;
        right: 0;
        top: 100px;
        width: 167px;
        height: 180px;
        z-index: -1;

        @include laptop() {
            width: 430px;
            height: 465px;
            top: -15px;
            background-size: auto;
        }
    }

    &__fruit-left {
        background-image: url('../../assets/join-localo/fruit-left.svg');
        background-repeat: no-repeat;
        background-position: center;
        display: none;
        position: absolute;
        z-index: -1;

        @include laptop() {
            display: block;
            width: 319px;
            height: 343px;
            bottom: 10px;
            left: -100px;
        }

        @include desktop() {
            width: 268px;
            height: 289px;
            left: -80px;
            bottom: 85px;
        }
    }

    .error {
        color: var(--color-raspberry-sorbet);
    }
}