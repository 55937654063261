@import '../../scss/mixins';

.join-localo-navs {
    background-color: var(--color-cornflower-blue);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 15px 10px;

    &__nav {
        color: #FFFFFF;
        font-size: 18px;
        line-height: 22px;
        font-weight: bold;
        margin: 8px 0;
    }
    
    @include tablet() {
        flex-direction: row;
        padding: 18px 12px;
        
        &__nav {
            margin: 0 12px;
        }
    }

    @include laptop() {
        padding: 22px 15px;

        &__nav {
            font-size: 22px;
            margin: 0 45px;
        }
    }

    @include desktop() {
        padding: 32px 15px;

        &__nav {
            font-size: 36px;
            line-height: 45px;
        }
    }
}