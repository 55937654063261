@import '../../scss/colour';
@import '../../scss/mixins';

.order__confirmation {
  &-layout {
    margin: 19px auto;
    max-width: 90%;
    @include tablet {
      max-width: 70%;
    }
    @include tablet {
      max-width: 70%;
      position: relative;
    }
    @include laptop {
      max-width: 65%;
    }

    @include desktop {
      max-width: 45%;
    }
  }

  &-heading {
    font-size: 21px;
    font-weight: 700;
    font-style: normal;
    color: var(--color-raspberry-sorbet);
    text-align: center;
    letter-spacing: -0.01em;

    @include tablet {
      font-size: 28px;
      line-height: 35px;
    }
    @include laptop {
      font-size: 30px;
      line-height: 38px;
    }
    @include desktop {
      font-size: 36px;
      line-height: 45px;
    }
  }
  &-summary {
    color: var(--color-raspberry-sorbet);
    text-align: center;
    font-style: normal;
    font-weight: 700;
    text-align: center;

    @include tablet {
      font-size: 15px;
      line-height: 19px;
      letter-spacing: -0.03em;
    }

    @include laptop {
      font-weight: bold;
      font-size: 30px;
      line-height: 37px;
      margin-bottom: 16px;
    }
    @include desktop {
      font-size: 36px;
      font-weight: 700;
      line-height: 45px;
      letter-spacing: -0.01em;
    }
  }
  &-number {
    font-size: 28px;
    font-weight: 700;
    line-height: 38px;
    margin: 10px 0;
    color: var(--color-raspberry-sorbet);
    text-align: center;
    font-style: normal;
    letter-spacing: -0.01em;

    @include tablet {
      font-size: 22px;
      line-height: 30px;
    }

    @include laptop {
      font-weight: bold;
      font-size: 40px;
      line-height: 50px;
    }

    @include desktop {
      font-size: 48px;
      line-height: 60px;
      letter-spacing: -0.01em;
      width: 596px;
    }
  }
  &-paragraph {
    margin: 19px 0;
    font-size: 15px;
    color: #8c8c8c;
    line-height: 18.75px;
    font-weight: 300;
    font-style: normal;

    @include laptop {
      font-size: 17px;
      line-height: 21px;
    }

    @include desktop {
      font-size: 18px;
      font-weight: 300;
      line-height: 23px;
      letter-spacing: -0.01em;
      text-align: left;
    }
  }

  &-list_item {
    font-size: 28px;
    font-weight: 700;
    font-style: normal;
    line-height: 38px;
    margin: 20px 0 0 0;
    color: var(--color-raspberry-sorbet);
    text-align: center;
    @include tablet {
      font-size: 24px;
      line-height: 30px;
      letter-spacing: -0.01em;
      margin-top: 39px;
    }

    @include laptop {
      font-weight: bold;
      font-size: 30px;
      line-height: 37px;
    }
    @include desktop {
      font-size: 36px;
      font-weight: 700;
      line-height: 45px;
      letter-spacing: -0.01em;
    }
  }
  &-btn {
    position: absolute;
    padding: 13px 50px !important;
    display: block;
    top: 40px;

    @media screen and(max-width:1025px) {
      display: none !important;
    }
    @media screen and(min-width:1280px) {
      left: -220px !important;
    }
    @media screen and(min-width:1440px) {
      left: -330px !important;
    }
    @media screen and(min-width:1500px) {
      top: 85px;
      left: -330px !important;
    }
  }
  &-section_one {
    @include laptop {
      margin-bottom: 66px;
    }

    @include desktop {
      margin-top: 58px;
    }
    @include desktop {
      margin-top: 55px;
      margin-bottom: 73px;
    }
  }
  &-section_two {
    @include tablet {
      margin-top: 39px;
    }
    @include laptop {
      margin-top: 66px;
    }
    @include desktop {
      margin-top: 64px;
    }
  }

  &-section_three {
    display: none;
    @include laptop {
      display: block !important;
      margin-top: 41px;
    }

    @include desktop {
      margin: 36px 0 50px 0;
    }
  }
  &-section_four {
    @include laptop {
      margin-top: 37px;
      margin-bottom: 100px;
    }
  }
}
