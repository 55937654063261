@import-normalize;

@import './colour';
@import './typography';
@import './mixins';
@import './petals';

@import '../../node_modules/slick-carousel/slick/slick.scss';
@import '../../node_modules/slick-carousel/slick/slick-theme.scss';

body {
  margin: 0;
  &.modal-open {
    overflow: hidden;
  }
}

.container {
  display: grid;

  justify-content: center;

  > div {
    @include desktop {
      max-width: 1400px;
    }

    @include laptop {
      max-width: 940px;
    }

    @include mobileLandscape {
      max-width: 100%;
    }

    // @include mobile {
    //     width: 100%;
    // }
  }
}

.header-spacer {
  margin-top: 75px;
}

.button {
  color: var(--color-raspberry-sorbet);

  background: none;

  border: 2px solid var(--color-raspberry-sorbet);

  padding: 0 25px 0 25px;

  font-size: 18px;
  font-weight: 700;

  position: relative;

  z-index: 1;

  cursor: pointer;

  transition: 0.2s;
  outline: none !important;
  
  &.button-view-basket {
    padding: 10px 14px;
    font-size: 16px;
    border-width: 3px;
    display: flex;
    align-items: center;

    .fa-fw {
      font-size: 20px;
      margin-right: 6px;
    }
  }
  &-green {
    border: 2px solid var(--color-mint-green);
    color: var(--color-mint-green);

    &:hover {
      background-color: var(--color-mint-green);
      color: white;
    }
    &-solid {
      border: 2px solid var(--color-mint-green) !important;
      background-color: var(--color-mint-green);
      color: white;
      &:hover {
        background: transparent !important;
        color: var(--color-mint-green) !important;
      }
    }
  }

  &-raspberry {
    border: 2px solid var(--color-raspberry-sorbet);
    color: var(--color-raspberry-sorbet);
    &-solid {
      border: 2px solid var(--color-raspberry-sorbet);
      background-color: var(--color-raspberry-sorbet);
      color: white;
      &:hover {
        background: none;
        color: var(--color-raspberry-sorbet);
      }
    }
    &:hover {
      background-color: var(--color-raspberry-sorbet);
      color: white;
    }
  }

  &-solid {
    border: 2px solid var(--color-raspberry-sorbet);
    background-color: var(--color-raspberry-sorbet);
    color: white;
  }
}

p {
  margin-block-start: 0;
  margin-block-end: 0;
}

.btn-animation {
  outline: none !important;
  @include laptop {
    transition: transform 100ms ease-out;
    position: relative;
    &:hover {
      transform: translateY(3px);
      transform-origin: bottom;
      &::before {
        content: '';
        // background-color: rgba(black, 0.1);
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
      }
    }
  }
}

.btn-solid {
  border: 2px solid var(--color-raspberry-sorbet);
  background-color: var(--color-raspberry-sorbet);
  color: white;
}

.l-modal {
  padding: 18px 20px;
  box-sizing: border-box;

  &__title {
    font-weight: bold;
    font-size: 52px;
    line-height: 50px;
    margin-bottom: 20px;
    color: #55C234;
    text-align: center;
  }

  p {
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    color: #8C8C8C;
    margin-bottom: 15px;

    @include mobileLandscape {
      font-size: 18px;
      line-height: 22px;
    }
  }

  &__input {
    height: 43px;
    font-weight: bold;
    border: 2px solid #8C8C8C;
    background: #ffffff;
    color: #8C8C8C;
    font-size: 16px;
    line-height: 20px;
    box-sizing: border-box;
    outline: none;
    display: block;
    padding: 8px;
    width: 100%;
    margin-bottom: 17px;
    &::placeholder {
        color: #8C8C8C;
        opacity: 1;
    }
    
    &:-ms-input-placeholder {
        color: #8C8C8C;
    }
    
    &::-ms-input-placeholder {
        color: #8C8C8C;
    }
  }

  &__btns {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .btn-no-thanks {
      background: none;
      color: #BFBFBF;
      height: 40px;
      padding: 0;
      font-size: 16px;
      font-weight: bold;
      cursor: pointer;
      border: none;
      outline: none;
      box-shadow: none;
    }
    .btn-register {
      background: #8DDC73;
      color: #ffffff;
      height: 40px;
      font-size: 16px;
      font-weight: bold;
      padding: 10px 20px;
      border: none;
      outline: none;
      box-shadow: none;
      cursor: pointer;
    }
  }

  &__thankyou {
    text-align: center;
    font-weight: 700;
    border-top: 1px solid #ddd;
    margin-top: 17px;
    padding-top: 17px;
    color: #8C8C8C;
  }

  &--freetreat {
    padding-top: 0;
    background: #FF005E;
    img {
      display: block;
      margin: 0 auto;
    }
    p {
      color: #ffffff;
    }
    .l-modal__title {
      text-align: left;
      color: #ffffff;
    }
    .btn-no-thanks {
      color: #ffffff;
    }
  }
}

.text-center {
  text-align: center;
}

@include laptop-down {
  #conversations-wrap {
    bottom: 60px !important;
  }
}