@import "../../scss/mixins";

.product-reel {
  margin-top: 10px;
  margin-bottom: 10px;
  @include tablet {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  @include laptop {
    margin-top: 25px;
    margin-bottom: 25px;
  }
  .container-component {
    @include laptop-down {
      max-width: 100%;
      min-width: 1px;
      padding-left: 7px;
    }
  }

  &__carousel {
    .swiper-slide {
      width: 218px;
      margin: 3px;

      @include mobileLandscape {
        width: 240px;
      }
    }
  }

  &__header {
    border-bottom: 1px solid var(--color-mid-charcoal);
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    align-items: center;

    @include laptop-down {
      padding-right: 7px;
      padding-left: 2px;
    }

    @include laptop {
      padding-bottom: 10px;
      align-items: flex-end;
      margin-bottom: 11px;
    }
  }
  &__name {
    font-weight: bold;
    color: var(--color-mid-charcoal);
    font-size: 21px;
    line-height: 34px;

    @include laptop {
      font-size: 28px;
      line-height: 32px;
    }

    @include desktop {
      font-size: 36px;
      line-height: 36px;
    }
  }
  &__link {
    color: #000000;
    font-weight: bold;
    font-size: 16px;
    line-height: 17px;
    cursor: pointer;
    margin-right: 14px;

    @include laptop {
      font-size: 20px;
      line-height: 21px;
      margin-right: 0;
    }
  }
}
