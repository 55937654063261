@import "../../scss/mixins";

.swiper-carousel {
  position: relative;

  .swiper-button-prev,
  .swiper-button-next {
    background-color: var(--color-raspberry-sorbet);
    width: 40px;
    height: 40px;
    box-sizing: border-box;
    border-radius: 100%;
    border: none;
    outline: none;
    margin-top: 0;
    transform: translateY(-50%);

    &::after {
      font-size: 18px;
      font-weight: bold;
      color: white;

      @include desktop {
        font-size: 28px;
      }
    }

    &.swiper-button-disabled {
      display: none;
    }

    @include desktop {
      width: 70px;
      height: 70px;
    }
  }

  .swiper-button-prev {
    left: -20px;
    @include desktop {
      left: -35px;
    }
    @include laptop-down {
      left: 10px;
    }
  }

  .swiper-button-next {
    right: -20px;
    @include desktop {
      right: -35px;
    }
    @include laptop-down {
      right: 10px;
    }
  }
}
