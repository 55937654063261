@import '../../scss/mixins';

.join-localo-sts {
    padding: 225px 0 50px;
    position: relative;
    text-align: center;

    @include laptop() {
        padding: 335px 0 90px;
    }

    &__title {
        color: var(--color-mint-green);
        font-weight: bold;
        font-size: 21px;
        line-height: 26px;
        text-align: center;
        letter-spacing: -0.01em;
        margin-bottom: 20px;
        
        @include laptop() {
            font-size: 40px;
            line-height: 50px;
            margin-bottom: 50px;
        }

        @include desktop() {
            font-size: 48px;
            line-height: 60px;
            margin-bottom: 60px;
        }
    }

    &__list {
        margin: 0 auto 15px;
        padding: 0;
        max-width: 270px;
        
        @include laptop() {
            display: inline-block;
            margin: 0 45px 60px;
            max-width: unset;
        }

        @include desktop() {
            margin: 0 45px 100px;
        }
    }

    &__item {
        list-style: none;
        list-style-type: none;
        position: relative;
        color: var(--color-grey);
        font-weight: bold;
        font-size: 17px;
        line-height: 21px;
        letter-spacing: 0.03em;
        padding-left: 25px;
        text-align: left;

        &::before {
            background-image: url('../../assets/join-localo/tick-icon.svg');
            background-position: center;
            background-size: contain;
            background-repeat: no-repeat;
            position: absolute;
            content: '';
            left: 0;
            top: 50%;
            width: 17px;
            height: 11px;
            transform: translateY(-50%);
        }

        & + & {
            margin-top: 15px;

            @include laptop() {
                margin-top: 10px;
            }
        }

        @include laptop() {
            font-size: 24px;
            line-height: 34px;
            padding-left: 40px;

            &::before {
                width: 22px;
                height: 15px;
            }
        }

        @include desktop() {
            font-size: 36px;
            line-height: 45px;
            padding-left: 60px;
            
            &::before {
                width: 32px;
                height: 25px;
            }
        }
    }

    &__note {
        background-image: url('../../assets/join-localo/lock.svg');
        background-position: left 5px;
        background-repeat: no-repeat;
        background-size: 24px 32px;
        padding-left: 30px;
        color: var(--color-grey);
        font-weight: 300;
        font-size: 16px;
        line-height: 20px;
        letter-spacing: -0.01em;
        text-align: left;
        max-width: 285px;
        margin: 30px auto 0;

        a {
            color: var(--color-grey);

            &:hover {
                color: var(--color-raspberry-sorbet);
            }
        }

        @include laptop() {
            font-size: 28px;
            line-height: 36px;
            background-size: 54px 73px;
            padding-left: 80px;
            max-width: 700px;
            margin-top: 0;
        }

        @include desktop() {
            padding-left: 100px;
            max-width: 800px;
            font-size: 30px;
            line-height: 37px;
        }
    }

    .selling-leaf-big {
        background-image: url('../../assets/join-localo/selling-leaf-big.svg');
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        position: absolute;
        top: 70px;
        right: 5px;
        width: 130px;
        height: 131px;
        pointer-events: none;
        z-index: 1;
        transform: rotate(-90deg);

        @include laptop() {
            background-size: auto;
            width: 240px;
            height: 243px;
            right: 20px;
        }

        @include desktop() {
            right: 50px;
            top: 20px;
        }
    }

    .selling-leaf-small {
        background-image: url('../../assets/join-localo/selling-leaf-small.svg');
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        position: absolute;
        top: 0;
        right: 160px;
        width: 82px;
        height: 82px;
        pointer-events: none;
        z-index: 1;
        transform: rotate(-70deg);

        @include tablet() {
            right: 200px;
            width: 72px;
            height: 72px;
        }

        @include laptop() {
            width: 121px;
            height: 122px;
            right: 300px;
        }

        @include desktop() {
            right: 460px;
        }
    }
}