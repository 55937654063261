@import '../../scss/mixins';


.bid-grid-header {
  display: flex;

  flex-direction: row;

  gap: 2em;

  h2 {
    font-weight: 700;
    color: var(--color-raspberry-sorbet);
  }

  .images {
    @include mobile {
      display: none;
    }
    @include laptop {
      display: unset;
    }
  }

  div {
    justify-self: end;
    align-self: flex-end;
    display: flex;
  }
}

.bid-grid-grid {
  display: grid;
  margin-top: 1em;
  margin-bottom: 1em;
  grid-template-columns: 1fr 1fr;
  gap: 1em;
}

.bid {
  max-width: 169px;
  max-height: 40px;

  img {
    max-width: 169px;
    max-height: 40px;
  }
}


.vendor {
  display: flex;
  flex-direction: column;

  max-width: 220px;

  gap: 5px;

  img {
    height: 208px;
    width: 220px;
  }
}

.vendor-link {
  display: flex;
  flex-direction: row;

  gap: 5px;
}

.vendor-link-website {
  padding: .5em;

  border: 1px solid #8C8C8C;

  text-align: center;

  font-weight: 700;
  color: #8C8C8C;

  flex-grow: 1;
}

.vendor-link-social {
  align-self: center;
  justify-self: center;

  display: grid;
  color: #8C8C8C;
  font-size: 32px;
}