@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap');

:root, button, input {
    font-family: 'Quicksand', sans-serif;
}

a {
    text-decoration: none;
}

h1, h2, h3, h4, h5, h6 {
    margin-block-start: 0;
    margin-block-end: 0;
    margin-inline-start: 0;
    margin-inline-end: 0;
}

.bold {
    font-weight: 700;
}

.purple-heading {
    color: var(--color-raspberry-sorbet);
    font-weight: 700;

    font-size: 2.2em;

    &.medium {
        font-size: 1.7em;
    }
}

.text {
    &-center {
        text-align: center;
    }
}