@import '../../scss/mixins';

.title-card {
    padding-top: 75px;
    padding-bottom: 75px;
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0% calc(100% - 20px));
    position: relative;

    &.contact-title {
        padding-bottom: 135px;
        position: relative;

        @include mobile {
            padding-bottom: 75px;
            padding-top: 95px;
        }

        @include mobileLandscape {
            padding-bottom: 75px;
            padding-top: 95px;
        }

        @include tablet {
            padding-bottom: 75px;
        }
        @include laptop {
            padding-bottom: 135px;
        }

        @include desktop {
            padding-bottom: 135px;
        }
        p {
            margin-top: 38px;
            margin-left: auto;
            margin-right: auto;
            @include mobile {
                max-width: 281px;
             }
            @include mobileLandscape {
               max-width: 281px;
            }
    
            @include tablet {
                max-width: 577px;
            }
            @include laptop {
                max-width: 998px; 
            }
    
            @include desktop {
                max-width: 1398px; 
            }
        }

        h2,h3 {
            @include mobile {
                font-size: 28px;
                max-width: 281px;
             }
            @include mobileLandscape {
               font-size: 28px;
               max-width: 281px;
            }
    
            @include tablet {
                font-size: 36px;
                max-width: 577px;
            }
            @include laptop {
                font-size: 40px;
                max-width: 998px; 
            }
    
            @include desktop {
                font-size: 48px;
                max-width: 1398px; 
            }
        }
        .overlay {
            opacity: 1;
        }
    }

    @include mobile () {
        padding-top: 40px;
        padding-bottom: 50px;
        h1 {
            font-size: 45px;
            color: #fff;
            font-weight: 700;
            margin: auto;
            text-align: center;
            position: relative;
            z-index: 3;
        }
    }

    @include mobileLandscape () {
        padding-top: 40px;
        padding-bottom: 50px;
        h1 {
            font-size: 28px;
            color: #fff;
            font-weight: 700;
            margin: auto;
            text-align: center;
            position: relative;
            z-index: 3;
        }
    }

    @include tablet () {
        padding-top: 60px;
        padding-bottom: 70px;
        h1 {
            font-size: 40px;
            color: #fff;
            font-weight: 700;
            margin: auto;
            text-align: center;
            position: relative;
            z-index: 3;
        }
    }

    @include laptop () {
        padding-top: 90px;
        padding-bottom: 100px;
        h1 {
            font-size: 55px;
            color: #fff;
            font-weight: 700;
            margin: auto;
            text-align: center;
            position: relative;
            z-index: 3;
        }
    }

    @include desktop {
        padding-top: 135px;
        padding-bottom: 235px;
        h1 {
            font-size: 85px;
            color: #fff;
            font-weight: 700;
            margin: auto;
            text-align: center;
            position: relative;
            z-index: 3;
        }
    }


    &.invert {
        clip-path: polygon(0 14%, 100% 0, 100% 100%, 0% 100%);
    }

    .overlay {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        opacity: 0.9;
        z-index: 2;

        &.mustard-mint {
            background: linear-gradient(135deg, var(--color-american-mustard) 16.7%, var(--color-mint-green) 83.95%);
        }

        &.raspberry-sorbet {
            background: linear-gradient(135deg, var(--color-raspberry-sorbet) 16.7%, var(--color-cornflower-blue) 83.95%);
        }

        &.green-blue {
            background: linear-gradient(135deg, #9CB6FF 16.7%, #8DDC73 83.95%);
        }
    }

    .background {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-size: cover;
    }

    h2, h3 {
        color: var(--color-white);
        font-size: 45px;
        font-weight: 700;
        margin: auto;
        text-align: center;
        position: relative;
        z-index: 3;

        @include mobileLandscape () {
            font-size: 28px;
        }

        @include tablet () {
            font-size: 40px;
        }

        @include laptop () {
            font-size: 55px;
        }
    }

    p {
        color: var(--color-white);
        z-index: 3;
        text-align: center;
        position: relative;

        font-weight: 700;

        @include mobile {
            font-size: 21px;
        }

        @include tablet {
            font-size: 28px;
        }

        @include laptop {
            font-size: 30px;
        }

        @include desktop {
            font-size: 36px;
        }
    }
}
