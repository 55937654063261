@import "../../scss/mixins";

.progress-bar {
    display: flex;
    justify-content: space-between;
    max-width: 780px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 15px;
    padding-left: 10px;
    padding-right: 10px;
    
    @include laptop {
        margin-bottom: 25px;
        padding-left: 0;
        padding-right: 0;
    }

    &__item {
        flex: 0 0 calc(33.3333% - 5px);
        max-width: calc(33.3333% - 5px);
        opacity: 0.25;

        &--current {
            opacity: 1;
        }

        @include laptop {
            flex: 0 0 calc(33.3333% - 8px);
            max-width: calc(33.3333% - 8px);
        }
    }

    &__title {
        font-size: 8px;
        line-height: 10px;
        font-weight: bold;
        color: #000000;
        margin-bottom: 5px;

        @include laptop {
            font-size: 12px;
        }
    }

    &__line {
        background-color: var(--color-raspberry-sorbet);
        height: 6px;
    }
}