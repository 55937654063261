@import '../../scss/mixins';

.join-localo-faq {
    padding: 50px 0;
    background-color: #FFFFFF;

    @include laptop() {
        padding: 90px 0;
    }

    @include desktop() {
        padding: 120px 0 160px;
    }

    &__title {
        color: var(--color-mint-green);
        font-weight: bold;
        font-size: 21px;
        line-height: 26px;
        text-align: center;
        letter-spacing: -0.01em;
        margin-bottom: 10px;
        
        @include laptop() {
            font-size: 40px;
            line-height: 50px;
            margin-bottom: 15px;
        }

        @include desktop() {
            font-size: 36px;
            line-height: 45px;
        }
    }

    &__desc {
        color: var(--color-grey);
        font-weight: 300;
        font-size: 16px;
        line-height: 20px;
        text-align: center;
        letter-spacing: -0.03em;
        max-width: 285px;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 25px;
        
        @include laptop() {
            font-size: 26px;
            line-height: 32px;
            max-width: unset;
            margin-bottom: 40px;
            margin-left: 0;
            margin-right: 0;
        }

        @include desktop() {
            font-size: 28px;
            line-height: 35px;
            margin-bottom: 60px;
        }
    }

    &__item {
        padding: 0 20px;

        @include mobileLandscape() {
            padding: 0;
            max-width: 325px;
            margin-left: auto;
            margin-right: auto;
        }

        @include tablet() {
            max-width: 430px;
        }

        @include laptop() {
            max-width: 750px;
        }

        @include desktop() {
            max-width: 920px;
        }
    }

    &__icon {
        background-image: url('../../assets/join-localo/down-arrow.svg');
        background-repeat: no-repeat;
        background-position: center;
        background-size: 14px;
        width: 14px;
        height: 14px;
        position: absolute;
        right: 5px;
        top: 50%;
        transform: rotate(0deg) translateY(-50%);

        @include laptop() {
            background-size: 32px;
            width: 32px;
            height: 32px;
        }

        &.is-open {
            transform: rotate(180deg) translateY(50%);
        }
    }

    &__que {
        color: var(--color-raspberry-sorbet);
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        display: flex;
        align-items: center;
        letter-spacing: 0.03em;
        padding: 10px 50px 10px 0;
        position: relative;
        cursor: pointer;
    
        @include laptop() {
            font-size: 24px;
            line-height: 30px;
        }

        @include desktop() {
            font-size: 32px;
            line-height: 40px;
        }    
    }

    &__ans {
        color: var(--color-mid-charcoal);
        font-weight: 300;
        font-size: 16px;
        line-height: 20px;
        letter-spacing: -0.01em;
        overflow: hidden;
        transition: max-height 0.45s cubic-bezier(1, 0, 1, 0);
        height: auto;
        max-height: 9999px;

        @include laptop() {
            font-size: 22px;
            line-height: 25px;
        }

        @include desktop() {
            font-size: 30px;
            line-height: 37px;
        }

        &.is-collapsed {
            max-height: 0;
            transition: max-height 0.45s cubic-bezier(0, 1, 0, 1);
        }
    }

    &__ans-content {
        padding-top: 10px;
        margin-bottom: 25px;

        @include laptop() {
            margin-bottom: 40px;
        }

        @include desktop() {
            margin-bottom: 80px;
        }
    }
}