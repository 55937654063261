@keyframes pulse {
  0% {
    transform: scale(0.5);
    opacity: 0.1;
  }

  30% {
    transform: scale(1);
    opacity: 0.2;
  }

  100% {
    transform: scale(0.95);
    opacity: 0;
  }
}

.header-basket {
  .pulse {
    pointer-events: none;
    position: absolute;
    margin: -9px 0 0 -5px;
    .circle {
      width: 40px;
      height: 40px;
      background-color: red;
      border-radius: 50%;
      position: relative;

      animation-name: pulse;
      animation-duration: 2s;
      animation-iteration-count: infinite;
    }
  }
}