@import '../../scss/mixins';

.join-localo-hero {
    background: linear-gradient(135deg, #FF005E 16.7%, #DEC400 83.95%);
    display: flex;
    align-items: center;
    justify-content: center;
    height: 289px;
    position: relative;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);

    &::before {
        background-image: url('../../assets/join-localo/hero-section-leaf.svg');
        background-repeat: no-repeat;
        position: absolute;
        content: '';
        width: 154px;
        height: 89px;
        top: 0;
        left: 0;
        pointer-events: none;
        background-size: contain;   
    }

    &__title {
        color: #FFFFFF;
        font-size: 45px;
        line-height: 45px;
        font-weight: bold;
        letter-spacing: -0.03em;
        text-align: center;
        padding-bottom: 30px;
        margin-top: auto;
    }
    @include mobileLandscape() {
        height: 139px;

        &__title {
            max-width: 345px;
            font-size: 28px;
            line-height: 35px;
            padding-bottom: 0;
            margin-top: 0;
        }
    }
    @include tablet() {
        height: 304px;

        &__title {
            max-width: 575px;
            font-size: 40px;
            line-height: 50px;
        }

        &::before {
            width: 164px;
            height: 99px;
        }
    }
    @include laptop() {
        height: 552px;

        &__title {
            max-width: 585px;
            font-size: 55px;
            line-height: 69px;
        }

        &::before {
            width: 242px;
            height: 239px;
        }
    }
    @include desktop() {
        height: 700px;

        &__title {
            font-size: 85px;
            max-width: 1160px;
            line-height: 106px;
        }

        &::before {
            background-size: cover;
            width: 342px;
            height: 339px;
        }
    }
}