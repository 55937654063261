@import "../../scss/mixins";

.product-back {
  &__btn {
    border: 2px solid var(--color-mint-green);
    color: var(--color-mint-green);
    background-color: white;
    cursor: pointer;
    font-size: 18px;
    font-weight: bold;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 0;
    transition: 0.2s;
    outline: none;
    box-shadow: none;
    &:before {
      content: "";
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' version='1.1' id='Layer_1' x='0px' y='0px' viewBox='0 0 224.008 224.008' style='enable-background:new 0 0 224.008 224.008;fill:%238DDC73;' xml:space='preserve'%3E%3Cg%3E%3Cg%3E%3Cpath d='M216.075,197.059l-171-85.5l171-84.9c13.1-6.7,8.4-26.6-6.3-26.6v0c-2.1,0-4.7,0.5-6.3,1.6l-195.6,98 c-10.5,4.7-10.5,19.4,0,24.6l195.6,98C220.275,230.659,232.875,205.459,216.075,197.059z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
      height: 8px;
      width: 8px;
      display: inline-block;
      margin-right: 5px;
      margin-top: 2px;
    }
    &:hover {
      border: 2px solid var(--color-mint-green);
      color: white;
      background-color: var(--color-mint-green);
      &::before {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' version='1.1' id='Layer_1' x='0px' y='0px' viewBox='0 0 224.008 224.008' style='enable-background:new 0 0 224.008 224.008;fill:%23FFFFFF;' xml:space='preserve'%3E%3Cg%3E%3Cg%3E%3Cpath d='M216.075,197.059l-171-85.5l171-84.9c13.1-6.7,8.4-26.6-6.3-26.6v0c-2.1,0-4.7,0.5-6.3,1.6l-195.6,98 c-10.5,4.7-10.5,19.4,0,24.6l195.6,98C220.275,230.659,232.875,205.459,216.075,197.059z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
      }
    }
    @include mobile {
      display: none;
    }
    @include mobileLandscape {
      display: none;
    }
    @include tablet {
      display: none;
    }
    @include laptop {
      display: flex;
      height: 40px;
      width: 89px;
    }
    @include desktop {
      height: 61px;
      width: 154px;
    }
  }
}
