@import "../../scss/mixins";

.product-view {
  .container-component {
    @include laptop-down {
      max-width: 100%;
      min-width: 1px;
      padding-left: 10px;
      padding-right: 10px;
    }
  }
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 11px;

    @include laptop-down {
      padding-right: 7px;
      padding-left: 2px;
    }
  }

  &__name {
    font-weight: bold;
    color: #000000;
    font-size: 21px;
    line-height: 26px;

    &--pink {
      color: var(--color-raspberry-sorbet);
    }

    @include tablet {
      font-size: 28px;
      line-height: 45px;
    }
  }
  &__row {
    display: flex;
    flex-wrap: wrap;
    margin-left: -10px;
    margin-right: -10px;

    @include laptop {
      margin-left: -15px;
      margin-right: -15px;
    }
  }

  &__cell {
    box-sizing: border-box;
    flex: 0 0 50%;
    max-width: 50%;
    padding-left: 10px;
    padding-right: 10px;
    margin-bottom: 20px;

    @include tablet {
      flex: 0 0 33.33%;
      max-width: 33.33%;
    }

    @include laptop {
      flex: 0 0 25%;
      max-width: 25%;
      margin-bottom: 30px;
      padding-left: 15px;
      padding-right: 15px;
    }

    @include desktop {
      flex: 0 0 16.66%;
      max-width: 16.66%;
    }
  }

  .product-card__title {
    @include mobileLandscape-down {
      font-size: 14px;
      line-height: 17px;
    }
  }

  .product-card__subText {
    @include mobileLandscape-down {
      margin-top: 2px;
      font-size: 11px;
      line-height: 12px;
    }
  }

  .product-loadmore {
    font-size: 17px;
    line-height: 22px;
    margin-top: 30px;
    margin-bottom: 60px;
    font-weight: bold;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;

    a {
      color: var(--color-raspberry-sorbet);
    }

    @include tablet {
      font-size: 28px;
      line-height: 45px;
      margin-top: 40px;
      margin-bottom: 80px;
    }
  }
}
