@import "../../scss/mixins";

.find-local-shops {
  background-image: url("../../assets/find-local-shops/local-shops-bg-tablet.png");
  background-position: 85% center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  margin: 0 auto 10px;
  position: relative;
  display: flex;
  align-items: center;
  height: 494px;
  @include tablet {
    background-position: center;
    height: 380px;
    max-width: 600px;
  }
  @include laptop {
    margin: 0 auto 50px;
    background-image: url("../../assets/find-local-shops/local-shops-bg.png");
    max-width: 850px;
    height: 580px;
    background-position: initial;
  }
  @include desktop {
    height: 626px;
    max-width: 922px;
  }
  &__form {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    align-self: flex-end;
    width: 100%;
    @include tablet {
      align-self: center;
      display: block;
      margin-left: 35px;
      text-align: left;
    }
    @include laptop {
      margin-left: 65px;
    }
  }

  &__title {
    color: white;
    font-weight: bold;
    font-size: 28px;
    line-height: 35px;
    text-align: center;
    max-width: 255px;
    text-shadow: 0px 5px 8px rgba(0, 0, 0, 0.2);
    @include tablet {
      font-size: 36px;
      line-height: 45px;
      text-align: left;
      max-width: 340px;
    }
    @include laptop {
      font-size: 48px;
      line-height: 60px;
    }
  }

  &__search {
    display: flex;
    // align-items: center;
    justify-content: center;
    // background: #ffffff;
    box-sizing: border-box;
    width: 100%;
    max-width: 342px;
    margin-top: 25px;
    margin-bottom: 120px;
    @include mobileLandscape {
      max-width: 420px;
    }
    @include tablet {
      max-width: 325px;
      margin-top: 25px;
      margin-bottom: 60px;
      justify-content: unset;
    }
    @include laptop {
      margin-top: 35px;
      max-width: 420px;
    }
    @include desktop {
      max-width: 460px;
    }
    .field-postcode {
      height: 69px;
      font-size: 25px;
      line-height: 35px;
      background: #ffffff;
      color: var(--color-raspberry-sorbet);
      font-size: 25px;
      line-height: 35px;
      font-weight: normal;
      box-sizing: border-box;
      border: none;
      outline: none;
      display: block;
      padding: 12px;
      width: 100%;
      &::placeholder {
        color: var(--color-raspberry-sorbet);
        opacity: 1;
      }
      &:-ms-input-placeholder {
        color: var(--color-raspberry-sorbet);
      }
      &::-ms-input-placeholder {
        color: var(--color-raspberry-sorbet);
      }
      @include tablet {
        height: 50px;
        font-size: 18px;
      }
      @include laptop {
        height: 69px;
        font-size: 25px;
        line-height: 35px;
        padding: 18px;
      }
    }
    .postcode-btn {
      background-color: var(--color-raspberry-sorbet);
      color: #ffffff;
      height: 55px;
      line-height: 55px;
      padding: 0 28px;
      font-weight: bold;
      font-size: 18px;
      cursor: pointer;
      margin-right: 8px;
      white-space: nowrap;
      @include tablet {
        height: 48px;
        line-height: 48px;
      }
      @include laptop {
        height: 55px;
        line-height: 55px;
        margin-right: 8px;
        padding: 0 38px;
      }
    }
  }
}
