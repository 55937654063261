@import '../../scss/colour';
@import '../../scss/mixins';

footer {
  background-color: var(--color-raspberry-sorbet);
  padding-top: 28px;
  padding-bottom: 60px;

  @include mobileLandscape {
    padding-top: 30px;
  }

  @include tablet {
    padding-bottom: 0;
  }

  @include laptop {
    padding-top: 50px;
  }

  .footer-layout {
    position: relative;
    .footer-logo {
      color: #fff;
      display: flex;
      flex-direction: column;
      align-items: center;

      @include tablet {
        align-items: flex-start;
        max-width: 553px;
        margin: auto;
      }

      @include laptop {
        max-width: unset;
        margin: unset;
      }

      svg {
        height: 32px;

        @include mobileLandscape {
          height: 37px;
        }

        @include tablet {
          height: 28px;
        }

        @include laptop {
          height: 30px;
        }

        @include desktop {
          height: 28px;
        }
      }

      p {
        font-size: 12px;
        margin-block-start: 0;

        @include mobileLandscape {
          font-size: 14px;
        }

        @include tablet {
          font-size: 11px;
        }

        @include laptop {
          font-size: 11px;
        }

        @include desktop {
          font-size: 11px;
        }
      }
    }

    .upper-row {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      margin-top: 16px;
      position: relative;
      @include mobile {
        justify-content: center;
      }

      @include mobileLandscape {
        justify-content: space-between;
      }

      @include tablet {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        max-width: 553px;
        margin-left: auto;
        margin-right: auto;
        gap: 33px;
      }

      @include laptop {
        grid-template-columns: 1fr 1fr;
        max-width: unset;
        justify-content: flex-end;
      }

      @include desktop {
        grid-template-columns: 1fr 1fr 1fr;
        top: 30px;
      }

      .footer-newsletter {
        display: flex;
        flex-direction: column;
        max-width: 430px;

        @include mobile {
          max-width: 100%;
        }

        @include mobileLandscape {
          max-width: 300px;
          margin: 0 auto;
        }

        @include tablet {
          max-width: 450px;
          margin: 0 auto 0 0;
        }

        @include laptop {
          max-width: 400px;
        }

        @include desktop {
          max-width: 450px;
          margin: 0 auto 0 0;
        }

        p {
          font-weight: 700;
          color: #fff;
          margin: auto;
          margin-top: 0;
          margin-bottom: 12px;
          white-space: nowrap;
        }

        form {
          margin: 0;
          max-width: auto;
          gap: 8px;
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          grid-template-columns: auto;
          @include mobile {
            display: flex;
            justify-content: center;
            flex-direction: column;
            max-width: 100%;
          }
          @include mobileLandscape {
            display: flex;
            justify-content: center;
            max-width: 100%;
          }
          @include tablet {
            display: flex;
            flex-direction: column;
            width: 100%;
          }
          @include laptop {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
          }

          @include desktop {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
          }

          input {
            height: 24px;
            padding: 13px;
            border: none;
            text-align: center;
            width: 100%;
            @include mobile {
              display: flex;
              justify-content: center;
              max-width: 88%;
            }
            @include mobileLandscape {
              display: flex;
              justify-content: center;
              max-width: 91.5%;
            }
            @include tablet {
              width: 88%;
            }
            @include laptop {
              width: 100%;
            }

            @include desktop {
              width: 100%;
            }
          }

          button {
            background-color: var(--color-mint-green);
            color: #fff;
            font-weight: 700;
            font-size: 18px;
            padding: 13px 50px;
            border: none;
            @include mobile {
              font-size: 18px;
              padding: 13px 30.8px;
            }
            @include mobileLandscape {
              font-size: 18px;
              padding: 13px 36.8px;
            }
            @include tablet {
              font-size: 18px;
              padding: 13px 50px;
            }
            @include laptop {
              font-size: 18px;
              padding: 13px 50px;
            }
            @include desktop {
              font-size: 18px;
              padding: 13px 50px;
            }
          }
        }

        @include mobileLandscape {
          flex: 0 0 100%;
        }

        @include tablet {
          flex: 0 0 31%;
          order: 2;
          position: relative;
          top: -55px;
          p {
            margin-left: 0;
          }

          form {
            margin-left: 0;
            max-width: unset;
            input {
              text-align: left;
            }
          }
        }

        @include laptop {
          order: unset;
          flex: 0 0 45%;
          top: unset;
          form {
            grid-template-columns: auto auto;
            margin-right: 0;
          }
        }

        @include desktop {
          order: unset;
          top: unset;
        }

        .message {
          margin-top: 5px;
          text-align: center;
        }
      }

      .links {
        flex: 0 0 30%;

        @include mobile {
          &:nth-of-type(1) {
            order: 1;
            top: 0;
            position: relative;
          }
          &:nth-of-type(3) {
            order: 3;
            top: 0;
            position: relative;
          }
          flex: 0 0 100%;
          display: grid;
          grid-template-columns: auto min-content;
        }

        @include mobileLandscape {
          &:nth-of-type(1) {
            order: 1;
            top: 0;
            position: relative;
          }
          &:nth-of-type(3) {
            order: 3;
            top: 0;
            position: relative;
          }
          flex: 0 0 100%;
          display: grid;
          grid-template-columns: auto min-content;
        }
        @include tablet {
          &:nth-of-type(1) {
            flex: 0 0 auto;
          }
          &:nth-of-type(3) {
            order: 1;
            top: 0;
            position: relative;
          }
          &:nth-of-type(2) {
            position: relative;
          }
          flex: 0 0 48%;
          display: grid;
          grid-template-columns: auto min-content;
        }

        @include laptop {
          &:nth-of-type(1) {
            order: unset;
            top: unset;
            top: 0;
            position: relative;
            flex: 0 0 auto;
          }
          &:nth-of-type(2) {
            order: unset;
            top: unset;
            position: relative;
          }
          top: unset;
          order: unset;
          flex: 0 0 45%;
          margin-left: 20px;
        }

        @include desktop {
          flex: 0 0 35%;
          top: unset;
          order: unset;
          display: flex;
          flex-direction: row;
          margin-left: 0;
        }

        .cols {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          max-width: 260px;
          width: 100%;
          margin-right: 11px;

          @include mobile {
            max-width: 100%;
            margin-top: 32px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            text-align: center;
          }

          @include mobileLandscape {
            max-width: 100%;
            margin-top: 32px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            text-align: center;
          }

          @include tablet {
            max-width: unset;
            margin-top: unset;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            text-align: left;
            margin-right: 0;
          }

          @include laptop {
            margin-top: unset;
            align-items: flex-start;
            flex-direction: row;
          }

          @include desktop {
            margin-top: unset;
            flex-direction: row;
          }

          ul {
            margin-block-start: 0;
            padding-inline-start: 0;
            list-style: none;
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            @include tablet {
              align-items: flex-start;
            }

            @include laptop {
              align-items: flex-start;
            }

            @include desktop {
              align-items: flex-start;
            }
            li {
              margin-bottom: 7px;
              &:last-of-type {
                margin-bottom: 0;
              }

              a {
                color: white;
                font-size: 14px;
              }
            }
          }

          .primary {
            font-weight: 700;
            margin-right: 18px;
            width: 100%;
            @include mobile {
              margin-right: 0px;
            }
            @include mobileLandscape {
              margin-right: 0px;
            }
            @include tablet {
              margin-right: 0px;
            }
            @include laptop {
              width: 50%;
              margin-right: 18px;
            }
            @include desktop {
              margin-right: 18px;
            }
          }

          .secondary {
            font-weight: 700;
            width: 100%;
          }
        }

        .social {
          list-style: none;
          display: flex;
          flex-direction: column;
          position: relative;
          top: 20px;
          gap: 15px;
          justify-content: center;
          margin-block-start: 0;
          padding-inline-start: 0;
          margin-bottom: 0;

          @include mobile {
            flex-direction: row;
            justify-content: center;
            top: 0;
          }

          @include mobileLandscape {
            flex-direction: row;
            justify-content: center;
            top: 0;
          }

          @include tablet {
            flex-direction: column;
            justify-content: start;
            margin-top: unset;
            top: 0;
            margin-left: auto;
          }
          @include laptop {
            flex-direction: column;
            justify-content: flex-start;
            margin-top: unset;
          }
          @include desktop {
            flex-direction: column;
            margin-top: unset;
            margin-right: 40px;
          }

          li {
            a {
              display: flex;
              width: 40px;
              height: 40px;
              background-color: white;
              border-radius: 20px;
              justify-content: center;
              align-items: center;
              @include tablet {
                width: 31px;
                height: 31px;
              }
              @include laptop {
                width: 40px;
                height: 40px;
              }
              @include desktop {
                width: 40px;
                height: 40px;
              }
              svg {
                font-size: 24px;
                color: var(--color-raspberry-sorbet);
                @include tablet {
                  font-size: 20px;
                  color: var(--color-raspberry-sorbet);
                }
                @include laptop {
                  font-size: 24px;
                  color: var(--color-raspberry-sorbet);
                }
                @include desktop {
                  font-size: 24px;
                  color: var(--color-raspberry-sorbet);
                }
              }
            }
          }
        }
      }
    }

    .lower-row {
      @include mobile {
        height: unset;
      }
      @include mobileLandscape {
        height: unset;
      }

      @include tablet {
        height: 60px;
      }

      @include laptop {
        height: unset;
      }

      @include desktop {
        height: unset;
      }
      .copyright {
        position: relative;
        top: -50px;
        padding-left: 10px;
        padding-right: 10px;
        color: white;
        font-size: 14px;
        text-align: center;
        font-weight: 400;
        margin: auto;
        padding-bottom: 50px;
        line-height: 17px;
        letter-spacing: 0.03em;

        @include mobile {
          max-width: 332px;
          padding: 0;
          padding-bottom: 50px;
          top: 0;
          margin-top: 20px;
        }

        @include mobileLandscape {
          max-width: 350px;
          padding: 0;
          padding-bottom: 50px;
          top: 0;
        }

        @include tablet {
          max-width: 281px;
          text-align: left;

          left: 50px;
          top: -345px;
        }

        @include laptop {
          max-width: unset;
          left: unset;
          top: -150px;
          margin: 0;
          max-width: 400px;
        }

        @include desktop {
          max-width: 397px;
          left: unset;
          font-size: 14px;
        }
      }
    }

    .dev-by-row {
      font-size: 14px;
      color: #ffffff;
      position: absolute;
      width: 100%;
      text-align: center;
      bottom: 20px;
      left: 0;
      right: 0;

      @include tablet() {
        bottom: 75px;
      }

      @include laptop() {
        bottom: 20px;
      }

      a {
        font-weight: 700;
        color: #ffffff;
      }
    }
  }
}

.footer {
  &--inBasket {
    @include tablet-down {
      padding-bottom: 28px;
      margin-bottom: 40px;
    }
    @include mobileLandscape-down {
      background-color: transparent;
      .footer-layout,
      .upper-row,
      .lower-row,
      .dev-by-row {
        display: none !important;
      }
    }
  }
}
