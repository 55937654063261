@import '../../scss/mixins';

.shop-product-list-item { 
  cursor: pointer;
}
.shop-product-list {
  @include mobile {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 5px;
    justify-content: center;
    align-items: center;
  }

  @include mobileLandscape {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
  }

  @include tablet {
    display: grid;
    grid-template-columns: 1fr;
    gap: 10px;
  }

  @include laptop {
    display: flex;
    flex-direction: column;
  }

  @include desktop {
    display: flex;
    flex-direction: column;
  }
}
