@import "../../scss/mixins";

.product-navigator {
  margin-bottom: 17px;
  @include tablet {
    margin-bottom: 30px;
    margin-top: 30px;
  }
  .container-component {
    @include laptop-down {
      max-width: 100%;
      min-width: 1px;
      padding-left: 7px;
    }
  }

  .all-cate {
    background-image: url("../../assets/products/all-cate.svg");
    background-repeat: no-repeat;
    background-position: center;
    width: 67px;
    height: 67px;
    display: block;
    position: absolute;
    margin: auto;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
  }

  .all-shops-icon {
    background-image: url("../../assets/products/all-shops.svg");
    background-repeat: no-repeat;
    background-position: center;
    width: 67px;
    height: 67px;
    display: block;
    position: absolute;
    margin: auto;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
  }

  &__carousel {
    .swiper-slide {
      width: 92px;
      margin: 3px;

      @include tablet {
        width: 120px;
      }

      @include desktop {
        width: 170px;
      }
    }
  }

  &__item {
    cursor: pointer;
    box-sizing: border-box;
    overflow: hidden;
    position: relative;
    background: #ffffff;
    color: #000000;
    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.15);
    border-radius: 4px;

    &.isSelected {
      .product-navigator__title {
        background-color: #55c234;
        color: white;
      }
    }
  }

  &__img {
    border-radius: 4px;
    height: 81px;
    position: relative;
    overflow: hidden;
    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.15);

    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
      transform: scale(1.075);
    }
  }

  &__title {
    font-weight: normal;
    font-size: 12px;
    line-height: 13px;
    text-overflow: ellipsis;
    overflow: hidden;
    position: relative;
    white-space: nowrap;
    padding: 8px 6px;

    @include mobileLandscape {
      font-size: 14px;
      line-height: 15px;
    }

    @include desktop {
      font-weight: bold;
      font-size: 18px;
      line-height: 22px;
      padding: 8px 12px;
    }
  }
}
