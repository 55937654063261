@import "../../scss/mixins";

.promotion {
  padding-bottom: 10px;
  .container-component {
    @include laptop-down {
      max-width: 100%;
      min-width: 1px;
      padding-right: 7px;
      padding-left: 7px;
    }
  }

  &__carousel {
    .slick-dots {
      bottom: auto;
      right: 5px;
      top: 5px;
      width: auto;

      li {
        margin: 0 2px;

        button {
          border: 2px solid #ffffff;
          padding: 0;
          background: #ffffff;
          border-radius: 16px;
          margin: 0 auto;
          width: 12px;
          height: 12px;

          @include tablet {
            width: 16px;
            height: 16px;
          }

          &::before {
            content: "";
          }
        }

        &.slick-active {
          button {
            background: none;
          }
        }

        @include tablet {
          margin: 0 5px;
        }
      }
    }
  }

  &__card {
    background: #c4c4c4;
    position: relative;
    overflow: hidden;
  }

  &__img {
    height: 136px;
    position: relative;
    overflow: hidden;
    // padding-top: 56.25%;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      position: absolute;
      top: 0;
    }

    @include tablet {
      height: 147px;
    }

    @include desktop {
      height: 177px;
    }
  }

  &__textHolder {
    // background-color: rgba(#b690c5, 0.85);
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 10px 15px;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: flex-end;

    @include tablet {
      align-items: center;
    }
  }

  &__text {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    color: white;
    font-weight: bold;
    max-width: 250px;
    font-size: 21px;
    line-height: 26px;

    @include tablet {
      font-size: 50px;
      line-height: 62px;
      max-width: 550px;
    }
  }
}
