@import '../../scss/mixins';

.join-localo-why {
    color: var(--color-grey);
    position: relative;
    padding-top: 295px;
    
    @include laptop() {
        color: #FFFFFF;
        padding: 180px 0 160px;
        margin-top: -40px;
    }

    @include desktop() {
        margin-top: -50px;
    }

    &__hero {
        background-image: url('../../assets/join-localo/delivery-hero.jpg');
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        clip-path: polygon(0% 30px, 100% 0%, 100% 90%, 0% 100%);
        position: absolute;
        height: 295px;
        width: 100%;
        top: 0;
        left: 0;
        z-index: -1;
        
        &::before {
            position: absolute;
            content: none;
            clip-path: polygon(0% 90px, 100% 0%, 100% 90%, 0% 100%);
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            z-index: -1;
            background-color: #000;
            opacity: 0.65;
        }

        @include laptop() {
            background-attachment: fixed;
            clip-path: polygon(0% 90px, 100% 0%, 100% 90%, 0% 100%);
            right: 0;
            bottom: 0;
            height: 100%;

            &::before {
                content: '';
            }
        }
    }

    
    &__list {
        display: flex;
        flex-direction: column;
        
        @include laptop() {
            margin: 0 -20px;
            flex-direction: row;
        }
    }

    &__item {
        padding: 0 20px;
        box-sizing: border-box;

        &:first-child {
            margin-top: -25px;
        }

        & + & {
            margin-top: 40px;
        }

        @include laptop() {
            flex: 0 0 33.33%;
            max-width: 33.33%;

            &:first-child {
                margin-top: 0;
            }

            & + & {
                margin-top: 0;
            }
        }
    }

    &__icon {
        background-color: var(--color-cornflower-blue);
        box-shadow: 0px 3px 10px 2px rgba(0, 0, 0, 0.25);
        width: 126px;
        height: 126px;
        border-radius: 100%;
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
            max-width: 62px;
        }

        @include laptop() {
            width: 182px;
            height: 182px;
            
            img {
                max-width: unset;
            }
        }

        @include desktop() {
            width: 202px;
            height: 202px;
        }
    }

    &__title {
        font-size: 21px;
        line-height: 26px;
        font-weight: bold;
        text-align: center;
        margin: 20px 0;

        @include laptop() {
            font-size: 30px;
            line-height: 37px;
        }

        @include desktop() {
            font-size: 36px;
            line-height: 45px;
            margin: 30px 0;
        }
    }

    &__desc {
        font-size: 16px;
        line-height: 20px;
        font-weight: 300;
        letter-spacing: -0.01em;
        padding: 0 15px;

        @include tablet() {
            font-size: 20px;
            line-height: 25px;
            padding: 0;
        }
    
        @include desktop() {
            font-size: 25px;
            line-height: 31px;
        }
    }
}