@import "../../scss/mixins";

.container-detailed-product {
  @media (max-width: 450px) and (min-width: 360px) {
    min-width: 1px;
    max-width: 100%;
  }
}

.detailed-product-grid {
  &-inner-true {
    & + .sticky-nav-wrap {
      display: none;
    }
  }
  & > div:first-of-type {
    @include desktop-down {
      &::-webkit-scrollbar {
        width: 6px;
      }
  
      &::-webkit-scrollbar-track {
        background: #e2e2e2;
      }
  
      &::-webkit-scrollbar-thumb {
        background: #cccccc;
      }
    }
  }
  @include laptop {
    max-width: unset;
    > div:first-of-type {
      display: unset;
      max-width: 305px;
      max-height: calc(100vh - 0px);
      overflow: auto;
      position: sticky;
      top: 0;
    }
    > div:last-of-type {
      display: unset;
    }
    display: grid;
    grid-template-columns: max-content auto;
    gap: 15px;
  }

  @include desktop {
    max-width: unset;
    > div:first-of-type {
      display: unset;
      max-width: unset;
    }
    > div:last-of-type {
      display: unset;
    }
    display: grid;
    grid-template-columns: max-content auto;
    gap: 30px;
  }

  @include mobile {
    max-width: 96%;
    margin: 0 auto;
    .toggle-product-right {
      &-false {
        display: none;
      }
      &-true {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
      }
    }
    .toggle-product-left {
      &-false {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: space-between;
      }
      &-true {
        display: none;
      }
    }
  }

  @include mobileLandscape {
    max-width: 96%;
    margin: 0 auto;
    .toggle-product-right {
      &-false {
        display: none;
      }
      &-true {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
      }
    }
    .toggle-product-left {
      &-false {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
      }
      &-true {
        display: none;
      }
    }
  }

  @include tablet {
    max-width: 96%;
    margin: 0 auto;
    .toggle-product-right {
      &-false {
        display: none;
      }
      &-true {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
      }
    }
    .toggle-product-left {
      &-false {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
      }
      &-true {
        display: none;
      }
    }
  }

  @include laptop {
    max-width: unset;
    transition: none;
    .toggle-product-right {
      &-false {
        display: block;
      }
      &-true {
        display: block;
      }
    }

    .toggle-product-left {
      &-false {
        display: block;
      }
      &-true {
        display: block;
      }
    }
  }
}
