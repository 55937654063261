@import "../../scss/mixins";

.how-localo-works {
  position: relative;
  padding-bottom: 150px;
  max-width: 100%;
  margin-bottom: -20px;
  overflow: hidden;

  h2 {
    font-weight: 700;
    font-size: 28px;
    color: var(--color-raspberry-sorbet);
    text-align: center;

    @include mobileLandscape {
      margin-top: 30px;
      font-size: 24px;
    }

    @include tablet {
      font-size: 28px;
    }

    @include laptop {
      font-size: 40px;
      margin-top: 0;
    }

    @include desktop {
      font-size: 48px;
      line-height: 60px;
      text-align: center;
      letter-spacing: -0.01em;
    }
  }

  h3 {
    font-weight: 400;
    font-size: 20px;
    color: var(--color-raspberry-sorbet);
    text-align: center;
    margin-bottom: 35px;
    padding-top: 11px;

    @include mobileLandscape {
      font-size: 17px;
    }

    @include tablet {
      font-size: 23px;
      padding-top: 5px;
      max-width: 95%;
      margin: 0 auto 25px;
    }

    @include laptop {
      font-weight: 300;
      font-size: 26px;
      line-height: 32px;
      text-align: center;
      letter-spacing: 0.03em;
      max-width: 580px;
      margin: 0 auto;
      margin-bottom: 45px;
    }

    @include desktop {
      font-size: 28px;
      line-height: 35px;
      text-align: center;
      letter-spacing: -0.03em;
      margin-top: 10px;
      max-width: 100%;
      margin-bottom: 95px;
    }
  }

  .steps {
    display: grid;
    list-style: none;
    justify-content: center;
    margin-block-start: 0;
    padding-inline-start: 0;
    margin-bottom: 60px;
    align-items: flex-start;

    @include tablet {
      grid-template-columns: 1fr 1fr;
      display: flex;
      flex-direction: column;
      padding: 0 80px;
    }

    @include laptop {
      margin-bottom: 90px;
      display: grid;
      list-style: none;
      justify-content: center;
      padding: 0;
    }

    @include desktop {
      grid-template-columns: 1fr 1fr 1fr 1fr;
      margin-bottom: 146px;
    }

    .step {
      display: flex;
      justify-content: center;
      text-align: center;
      flex-direction: column;
      justify-content: space-between;
      margin-bottom: 30px;

      @include mobileLandscape {
        flex-wrap: wrap;
        position: relative;
        flex-direction: row;
        justify-content: center;
        h4 {
          width: 100%;
          flex: 0 0 70%;
          height: max-content;
        }
        p {
          width: 100%;
          flex: 0 0 70%;
          font-size: 12px;
        }
      }

      @include tablet {
        position: relative;
        flex-wrap: nowrap;
        width: 100%;
        h4 {
          width: 100%;
          flex: 0 0 70%;
          height: max-content;
        }
      }

      @include laptop {
        position: relative;
        margin-bottom: 40px;
        flex-wrap: wrap;
        h4 {
          width: 100%;
          flex: 0 0 70%;
          height: max-content;
        }
      }

      img {
        width: 185px;
        margin-bottom: 6px;
        margin-left: auto;
        margin-right: auto;

        @include mobileLandscape {
          margin-bottom: 16px;
          width: 20%;
        }

        @include tablet {
          width: 150px;
        }

        @include laptop {
          width: 180px;
        }

        @include desktop {
          width: 271px;
          margin-bottom: 30px;
        }
      }

      .step-desc {
        width: 100%;

        @include tablet {
          padding-top: 50px;
        }

        @include laptop {
          padding-top: 0;
        }
      }

      h4 {
        font-weight: 700;
        font-size: 28px;
        color: var(--color-cornflower-blue);
        margin-bottom: 4px;

        @include mobileLandscape {
          font-size: 24px;
        }

        @include tablet {
          font-size: 22px;
        }

        @include laptop {
          font-size: 30px;
          margin-bottom: 10px;
          text-align: center;
        }

        @include desktop {
          font-size: 26px;
          text-align: center;
        }
      }

      p {
        font-weight: 400;
        font-size: 20px;
        color: var(--color-grey);

        @include mobileLandscape {
          font-size: 17px;
        }

        @include tablet {
          font-size: 17px;
          max-width: 170px;
          margin-left: auto;
          margin-right: auto;
        }

        @include laptop {
          max-width: 100%;
          margin: 0 auto;
          font-size: 22px;
        }

        @include desktop {
          font-size: 20px;
          margin: 0 auto;
          max-width: 270px;
        }
      }
    }
  }

  .video-placeholder {
    width: auto;
    padding-top: 56.25%;
    background-color: var(--color-raspberry-sorbet);
    margin: auto;
  }

  .video-wrapper {
    max-width: 765px;
    width: 100%;
    margin: auto;

    iframe {
      display: block;
      border: none;
      width: 100%;
    }

    @include laptop {
      max-width: 610px;
    }

    @include desktop {
      max-width: 765px;
    }

    .video-16-9 {
      border: 5px solid #ffffff;
      padding-top: 56.25%;
      height: 0;
      position: relative;

      box-sizing: border-box;

      iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: 0;
      }
    }
  }
}
