.input {
  padding: 6px;
  font-size: 14px;
  border-radius: 8px;
  border: 1px solid var(--color-mid-charcoal);
  width: 100%;
  height: 30px;
  box-sizing: border-box;
  font-family: sans-serif;
  color: black;
}

.container {
  display: flex;
  flex-direction: row;
  gap: .5em;

  align-items: center;
  justify-content: center;

}

.messages {
  text-align: center;
  padding-top: 1em;

  > p {
    font-size: 0.9em;
  }
}