$mobile: 360px;
$mobile-landscape: 450px;
$tablet: 600px;
$laptop: 1000px;
$desktop: 1500px;

@mixin mobile {
  @media (min-width: #{$mobile}) {
    @content;
  }
}

@mixin mobileLandscape {
  @media (min-width: #{$mobile-landscape}) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: #{$tablet}) {
    @content;
  }
}

@mixin laptop {
  @media (min-width: #{$laptop}) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: #{$desktop}) {
    @content;
  }
}

@mixin mobileLandscape-down {
  @media (max-width: #{$mobile-landscape - 0.02}) {
    @content;
  }
}

@mixin tablet-down {
  @media (max-width: #{$tablet - 0.02}) {
    @content;
  }
}

@mixin laptop-down {
  @media (max-width: #{$laptop - 0.02}) {
    @content;
  }
}

@mixin desktop-down {
  @media (max-width: #{$desktop - 0.02}) {
    @content;
  }
}

.not-on {
  &-desktop {
    @include desktop {
      display: none;
    }
  }
  &-laptop {
    @include laptop {
      display: none;
    }
  }
}

.is-for {
  &-large {
    display: none;

    @include laptop {
      display: unset;
    }
  }
  &-small {
    display: unset;

    @include laptop {
      display: none;
    }
  }
}
