@import '../../scss/mixins';

.join-localo-newsletter {
    padding: 45px 0;
    background-color: #FFFFFF;
    text-align: center;

    @include laptop() {
        padding: 65px 0 95px;
    }

    &__title {
        color: var(--color-cornflower-blue);
        font-weight: bold;
        font-size: 21px;
        line-height: 26px;
        letter-spacing: -0.01em;
        margin-bottom: 30px;
        max-width: 270px;
        margin-left: auto;
        margin-right: auto;

        @include laptop() {
            font-size: 30px;
            line-height: 37px;
            max-width: unset;
            margin-bottom: 40px;
        }

        @include desktop() {
            font-size: 36px;
            list-style: 45px;
            margin-bottom: 50px;
        }
    }

    &__field {
        text-align: left;
        max-width: 240px;
        margin: 0 auto 30px;

        @include laptop() {
            max-width: 750px;
            margin-bottom: 40px;
        }   

        @include desktop() {
            max-width: 950px;
            margin-bottom: 50px;
        }
    }

    &__control {
        display: block;
        height: 40px;
        width: 100%;
        padding: 8px 12px;
        font-size: 16px;
        line-height: 1.5;
        font-weight: 500;
        margin: 0;
        font-family: inherit;
        color: var(--color-mid-charcoal);
        background-color: #fff;
        border: 3px solid var(--color-mint-green);
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        border-radius: 0;
        box-sizing: border-box;
        text-align: center;

        &:focus {
            outline: none;
            box-shadow: none;
        }

        &::placeholder {
            color: var(--color-mid-charcoal);
            opacity: 1;
        }
        
        &:-ms-input-placeholder {
            color: var(--color-mid-charcoal);
        }
        
        &::-ms-input-placeholder {
            color: var(--color-mid-charcoal);
        }

        @include laptop() {
            font-size: 30px;
            height: 70px;
            text-align: left;
            padding: 10px 20px;
        }
    }

    &__button {
        height: 50px;
        padding: 0 35px;
        background-color: var(--color-mint-green);
        color: #FFFFFF;
        font-size: 18px;
        margin: 0;
        font-family: inherit;
        display: inline-block;
        font-weight: bold;
        text-align: center;
        cursor: pointer;
        border: 3px solid transparent;
        border-radius: 0;
        transition: all .15s ease-in-out;

        &:hover {
            color: var(--color-mint-green);
            border-color: var(--color-mint-green);
            background-color: #FFFFFF;
        }

        @include laptop() {
            height: 60px;
            padding: 0 40px;
            font-size: 24px;
        }

        @include desktop() {
            height: 100px;
            font-size: 32px;
            padding: 0 50px;
        }
    }
}