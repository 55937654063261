@import '../../scss/mixins';

.shop-grid-item {
  display: flex;

  flex-direction: column;

  &-header {
    @include mobile {
      height: 139px;
      width: 168px;
    }

    @include mobileLandscape {
      height: 100px;
      width: 210px;
    }

    @include tablet {
      height: 142px;
      width: 281px;
    }

    @include laptop {
      height: 208px;
      width: 220px;
    }

    @include desktop {
      height: 208px;
      width: 208px;
    }

    > img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    > p {
      font-weight: 700;
      margin: 0;

      @include mobile {
        font-size: 15px;
      }

      @include mobileLandscape {
        font-size: 15px;
      }

      @include tablet {
        font-size: 20px;
      }
    }
  }

  &-meta-title {
    margin-top: 8px;
    font-weight: 700;
    color: #000000;
    @include mobile {
        font-size: 17px;
        line-height: 21px;
        margin-bottom: 10px;
    }
    @include mobileLandscape {
        font-size: 15px;
        line-height: 19px;
        margin-bottom: 4px;
    }
    @include tablet {
        font-size: 20px;
        line-height: 25px;
        margin-bottom: 10px;
    }
  }

  &-meta-address {
    margin-bottom: 16px;
    @include laptop {
      margin-bottom: 0;
    }
    p {
      font-weight: 500;
      margin: 0;
      padding: 0;
      color: #8c8c8c;

      & + p {
        margin-top: 2px;
      }

      @include mobile {
        font-size: 13px;
      }

      @include mobileLandscape {
        font-size: 13px;
      }

      @include tablet {
        font-size: 18px;
        line-height: 20px;
      }
    }
  }
}

.shop-grid-item-layout {
  box-sizing: border-box;
  @include laptop {
    &:hover {
      transition: transform 500ms ease-in-out;
      transform: translateY(7px);
    }
  }
}
