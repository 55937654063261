@import '../../scss/mixins';

.order__item {
  &-layout {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 10.5px 0;
    p,
    h2 {
      color: #8c8c8c;
    }

    .right {
      display: none;
      @include desktop {
        display: block;
      }
    }

    > div {
      display: flex;
      flex-direction: column;
    }
  }

  &-title {
    font-size: 20px;
    font-weight: 700;
    line-height: 25px;
    letter-spacing: -0.01em;
    @include tablet {
      font-size: 16px;
      line-height: 20px;
      letter-spacing: 0.03em;
      font-weight: 300;
    }
    @include laptop {
      font-size: 20px;
      line-height: 25px;
      letter-spacing: -0.01em;
      font-weight: 700;
    }
  }
  &-text {
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.03em;
    @include tablet {
      font-size: 16px;
      line-height: 20px;
      letter-spacing: 0.03em;
    }
  }

  &-one_time {
    display: flex;
    justify-content: space-between;
    align-items: center;

    > :first-of-type {
      margin-top: 21px;
    }

    &-text {
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: 25px;
      letter-spacing: -0.01em;
      text-align: left;
      color: #8c8c8c;
    }
  }
}
