@import "../../scss/mixins";

header {
  filter: drop-shadow(0px 5px 8px rgba(0, 0, 0, 0.2));
  background-color: white;
  position: relative;
  z-index: 200;

  .content-wrapper {
    padding: 10px;
    display: grid;
    grid-template-columns: 0fr min-content auto;
    margin: auto;

    @include laptop {
      grid-template-columns: 1fr min-content 1fr;
    }

    @include desktop {
      max-width: 1400px;
    }
  }

  .main-logo {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .logo-wrapper {
    position: relative;
    width: 100%;

    #logo {
      position: relative;
      margin: auto;
      z-index: 1;
    }

    #logo-background {
      display: none;
    }

    @include mobile {
      #logo {
        height: 32px;
        position: relative;
        margin-top: 4px;
        top: 0px;
      }
    }

    @include mobileLandscape {
      #logo {
        height: 32px;
        position: relative;
        margin-top: 4px;
        top: 0px;
      }
    }

    @include tablet {
      #logo {
        height: 32px;
        position: relative;
        margin-top: 4px;
        top: 0px;
      }
    }

    @include laptop {
      #logo {
        height: 32px;
        position: relative;
        margin-top: 4px;
        top: 0px;
      }
    }

    @include desktop {
      .logo-background {
        display: block;
        background-color: red;
        background-color: white;
        top: -40px;
        height: 150px;
        left: -82%;
        min-width: 538px;
        position: absolute;
        z-index: 0;
        border-radius: 50%;
      }
      #logo {
        height: 52px;
      }
    }
  }

  .header-buttons {
    justify-content: flex-end;
    display: flex;

    .link-account {
      &:hover {
        color: #b30042;
        .is-active {
          background-color: #b30042;
        }
      }
      &:active {
        color: #b30042;
        .is-active {
          background-color: #b30042;
        }
      }
    }

    .header-basket {
      &:hover {
        color: #b30042;
        .is-active {
          background-color: #b30042;
        }
      }
      &:active {
        color: #b30042;
        .is-active {
          background-color: #b30042;
        }
      }

      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .title {
        font-size: 12px;
        @include tablet {
          font-size: 9px;
        }
        @include laptop {
          font-size: 12px;
        }
        @include desktop {
          font-size: 12px;
        }
      }
    }

    .active {
      color: #b30042;
    }

    .active-bg {
      background-color: #b30042;
    }

    .link-account {
      &:active {
        color: #b30042;
      }
      // &:before {
      //   content: '';

      //   height: 40px;
      //   width: 40px;
      //   display: inline-block;
      //   @include mobile {
      //     height: 20px;
      //     width: 20px;
      //   }
      //   @include mobileLandscape {
      //     height: 25px;
      //     width: 25px;
      //   }
      //   @include tablet {
      //     height: 35px;
      //     width: 35px;
      //   }
      //   @include laptop {
      //     height: 35px;
      //     width: 35px;
      //   }
      // }
    }

    .menu-icon {
      cursor: pointer;
      &:before {
        content: "";
        height: 35px;
        width: 35px;
        position: relative;
        display: inline-block;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' version='1.1' id='Capa_1' x='0px' y='0px' viewBox='0 0 384.97 384.97' style='enable-background:new 0 0 384.97 384.97;fill:%23FF005E;' xml:space='preserve'%3E%3Cg%3E%3Cg id='Menu'%3E%3Cpath d='M12.03,84.212h360.909c6.641,0,12.03-5.39,12.03-12.03c0-6.641-5.39-12.03-12.03-12.03H12.03 C5.39,60.152,0,65.541,0,72.182C0,78.823,5.39,84.212,12.03,84.212z'/%3E%3Cpath d='M372.939,180.455H12.03c-6.641,0-12.03,5.39-12.03,12.03s5.39,12.03,12.03,12.03h360.909c6.641,0,12.03-5.39,12.03-12.03 S379.58,180.455,372.939,180.455z'/%3E%3Cpath d='M372.939,300.758H12.03c-6.641,0-12.03,5.39-12.03,12.03c0,6.641,5.39,12.03,12.03,12.03h360.909 c6.641,0,12.03-5.39,12.03-12.03C384.97,306.147,379.58,300.758,372.939,300.758z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
        @include mobile {
          height: 35px;
          width: 35px;
        }
        @include mobileLandscape {
          height: 35px;
          width: 35px;
        }
        @include tablet {
          height: 35px;
          width: 35px;
        }
        @include laptop {
          height: 35px;
          width: 35px;
        }
      }
    }

    a,
    .dropdown {
      font-size: 35px;
      margin-right: 20px;
      color: var(--color-raspberry-sorbet);
      text-align: center;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      span {
        margin-top: 5px;
        &.fa-layers {
          @include mobileLandscape-down {
            margin-top: 0;
          }
        }
      }
      @include tablet {
        font-size: 30px;
      }
      @include laptop {
        font-size: 35px;
      }
      @include desktop {
        font-size: 35px;
      }
      .account {
        @include mobile {
          font-size: 12px;
        }
        @include mobileLandscape {
          font-size: 12px;
        }
        @include laptop {
          font-size: 12px;
        }
        @include desktop {
          font-size: 12px;
        }

        display: block;
      }

      &.menu-link {
        display: none;
        @include laptop {
          display: block;
        }
      }

      &:last-child {
        margin-right: 0;
      }

      &.menu-icon {
        @include laptop {
          display: none;
        }
      }

      .dropdown-content {
        background-color: #fff;
        position: absolute;
        right: 0;
        top: calc(100% + 12px);
        width: fit-content;
        margin-right: 12px;
        visibility: hidden;
        padding: 12px 24px;
        opacity: 0;
        transition: opacity 0.5s, visibility 0s 0.5s;
        @include mobile {
          top: calc(100% + 0px);
          margin-right: 0px;
          height: 100vh;
          z-index: 9999;
        }
        @include mobileLandscape {
          top: calc(100% + 0px);
          margin-right: 0px;
          height: 100vh;
          z-index: 9999;
        }
        @include tablet {
          top: calc(100% + 0px);
          margin-right: 0px;
          height: 100vh;
          z-index: 9999;
        }
        @include laptop {
          top: calc(100% + 0px);
          margin-right: 0px;
          height: 100vh;
          z-index: 9999;
        }
        @include desktop {
          top: calc(100% + 0px);
          margin-right: 0px;
          height: 100vh;
          z-index: 9999;
        }
        ul {
          list-style: none;
          margin-block-start: 0;
          padding-inline-start: 0;
          margin-block-end: 0;
          li {
            text-align: right;
            a {
              display: inline;
              font-size: 36px;
              text-align: right;
              line-height: 50px;
            }
          }
        }

        &.show {
          visibility: visible;
          opacity: 1;
          transition: visibility 0s, opacity 0.5s;
        }
        button {
          border: none;
          background-color: transparent;
          display: block;
          margin-left: auto;
          margin-bottom: 10px;
        }
        .close-menu {
          &:before {
            content: "";
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' version='1.1' id='Capa_1' x='0px' y='0px' viewBox='0 0 512.001 512.001' style='enable-background:new 0 0 512.001 512.001;fill:%23FF005E;' xml:space='preserve'%3E%3Cg%3E%3Cg%3E%3Cpath d='M284.286,256.002L506.143,34.144c7.811-7.811,7.811-20.475,0-28.285c-7.811-7.81-20.475-7.811-28.285,0L256,227.717 L34.143,5.859c-7.811-7.811-20.475-7.811-28.285,0c-7.81,7.811-7.811,20.475,0,28.285l221.857,221.857L5.858,477.859 c-7.811,7.811-7.811,20.475,0,28.285c3.905,3.905,9.024,5.857,14.143,5.857c5.119,0,10.237-1.952,14.143-5.857L256,284.287 l221.857,221.857c3.905,3.905,9.024,5.857,14.143,5.857s10.237-1.952,14.143-5.857c7.811-7.811,7.811-20.475,0-28.285 L284.286,256.002z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
            height: 40px;
            width: 25px;
            height: 25px;
            display: inline-block;
          }
        }
      }
    }
  }
}
