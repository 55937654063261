@import "../../scss/mixins";

.product-card {
  background: #ffffff;
  box-sizing: border-box;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.15);
  border-radius: 2px;
  position: relative;

  &:hover {
    cursor: pointer;
  }

  &__badges {
    position: absolute;
    left: 10px;
    top: 10px;
    z-index: 2;
  }

  &__badge {
    border-radius: 25px;
    width: 25px;
    height: 25px;
    font-weight: bold;
    font-size: 13px;
    line-height: 25px;
    margin-bottom: 5px;
    text-align: center;
    color: white;

    &--vg {
      background-color: var(--color-purpleish);
    }

    &--v {
      background-color: var(--color-mint-green);
    }

    &--gf {
      background-color: var(--color-cornflower-blue);
    }
  }

  &__offer {
    background-image: url("../../assets/products/offer-badge.svg");
    background-size: 42px;
    background-repeat: no-repeat;
    font-weight: bold;
    font-size: 20px;
    line-height: 13px;
    color: white;
    line-height: 42px;
    text-align: center;
    width: 42px;
    height: 42px;
    position: absolute;
    top: 5px;
    right: 5px;
    z-index: 2;
  }

  &__img {
    // height: 145px;
    position: relative;
    overflow: hidden;
    padding-top: 56.25%;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      position: absolute;
      top: 0;
    }

    // @include tablet {
    //   height: 147px;
    // }

    // @include desktop {
    //   height: 140px;
    // }
  }

  &__content {
    padding: 10px 15px 15px 15px;
  }

  &__title {
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    @include tablet {
      font-size: 15px;
      line-height: 19px;
    }
  }

  &__subText {
    color: #8c8c8c;
    font-weight: 500;
    margin-top: 4px;
    font-size: 12px;
    line-height: 15px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    @include tablet {
      font-size: 11px;
      line-height: 18px;
    }
  }
}
