@import "../../scss/mixins";

.join-local-marketplace {
  text-align: center;
  padding-bottom: 54px;
  padding-top: 20px;
  position: relative;
  &::before {
    background-image: url("../../assets/home-hero/leaf-green.svg");
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    content: "";
    right: 0;
    top: -80px;
    z-index: -1;
    width: 121px;
    height: 123px;
    @include tablet {
      top: -90px;
      width: 131px;
      height: 134px;
    }
    @include laptop {
      top: -100px;
      width: 142px;
      height: 144px;
    }
    @include desktop {
      width: 172px;
      height: 175px;
    }
  }
  @include tablet {
    max-width: 800px;
    padding-bottom: 60px;
    margin-left: auto;
    margin-right: auto;
  }
  @include laptop {
    max-width: 850px;
    padding-bottom: 100px;
  }
  @include desktop {
    padding-top: 30px;
    max-width: 922px;
  }
  &__heading {
    color: var(--color-raspberry-sorbet);
    @include laptop {
      margin-bottom: 32px;
    }
    @include desktop {
      margin-bottom: 52px;
    }
  }
  &__headingTitle {
    font-weight: bold;
    font-size: 28px;
    line-height: 35px;
    margin-bottom: 22px;
    max-width: 320px;
    margin-left: auto;
    margin-right: auto;
    @include tablet {
      font-size: 36px;
      line-height: 45px;
      max-width: 430px;
      margin-bottom: 26px;
    }
    @include laptop {
      font-size: 40px;
      line-height: 50px;
      margin-bottom: 37px;
      max-width: 500px;
    }
    @include desktop {
      font-size: 48px;
      line-height: 60px;
      margin-bottom: 47px;
      max-width: 550px;
    }
  }
  &__headingDesc {
    font-weight: bold;
    font-size: 17px;
    line-height: 21px;
    max-width: 295px;
    margin-left: auto;
    margin-right: auto;
    @include tablet {
      font-size: 24px;
      line-height: 30px;
      max-width: 440px;
    }
    @include laptop {
      font-size: 24px;
      line-height: 40px;
      max-width: 620px;
    }
    @include desktop {
      font-size: 28px;
      line-height: 45px;
      max-width: 690px;
    }
  }
  &__item {
    box-sizing: border-box;
    color: var(--color-mid-charcoal);
    padding: 0 15px;
    max-width: 340px;
    margin-top: 35px;
    margin-left: auto;
    margin-right: auto;
    @include tablet {
      margin-top: 25px;
      max-width: 410px;
    }
    @include laptop {
      margin-top: 30px;
      max-width: 450px;
    }
    @include desktop {
      margin-top: 38px;
      max-width: 590px;
    }

    sub {
      font-size: 50%;
      vertical-align: -4px;
      margin-left: 2px;
    }

    .title {
      font-size: 26px;
      line-height: 35px;
      font-weight: bold;

      @include tablet {
        font-size: 28px;
        line-height: 35px;
      }

      @include laptop {
        font-size: 30px;
        line-height: 45px;
      }

      @include desktop {
        font-size: 34px;
        line-height: 43px;
      }
    }

    .desc {
      font-size: 20px;
      font-weight: 300;
      @include tablet {
        font-size: 21px;
        line-height: 26px;
      }
      @include laptop {
        font-size: 22px;
        line-height: 28px;
      }
      @include desktop {
        font-size: 28px;
        line-height: 32px;
      }
    }
  }
  img {
    max-width: 321px;
    margin-bottom: -20px;
    @include tablet {
      max-width: 313px;
      margin-bottom: 0;
    }
    @include laptop {
      max-width: initial;
    }
  }
}
