@import "../scss/mixins";

.about-block {
  margin-bottom: 20px;

  @include mobile {
    margin-bottom: 50px;
    margin-top: 20px;
    h1 {
      color: var(--color-raspberry-sorbet);
      text-align: center;
      grid-column: 1/4;
      font-weight: bold;
      font-size: 30px;
      line-height: 60px;
      display: block;

      text-align: center;
      letter-spacing: -0.01em;
    }
  }

  @include mobileLandscape {
    margin-bottom: 50px;
    margin-top: 20px;
  }

  @include tablet {
    margin-bottom: 50px;
    margin-top: 20px;
    font-size: 35px;
  }

  @include laptop() {
    margin-bottom: 50px;
    margin-top: 20px;
    padding: 20px 118px 50px 118px;
    h1 {
      font-size: 40px;
      line-height: 60px;
    }
  }

  @include desktop() {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 30px;
    margin-left: 55px;
    margin-right: 55px;
    margin-top: 23px;
    h1 {
      font-size: 48px;
      line-height: 60px;
    }

    h2 {
      grid-column: 1/4;
    }

    h3 {
      margin-left: 0 !important;
      margin-right: 0 !important;
      padding-bottom: 32px;
    }

    p {
      margin-left: 0 !important;
      margin-right: 0 !important;
    }
  }

  h2 {
    color: var(--color-raspberry-sorbet);
    font-size: 20px;
    font-weight: 300;
    text-align: center;
    padding-left: 10px;
    padding-right: 10px;

    @include mobileLandscape() {
      padding-left: 12px;
      padding-right: 12px;
      font-size: 17px;
    }

    @include tablet() {
      padding-left: 84px;
      padding-right: 84px;
      font-size: 23px;
    }

    @include laptop() {
      padding-left: unset;
      padding-right: unset;
      font-size: 26px;
    }
  }

  h3 {
    margin-top: 50px;
    color: var(--color-mint-green);
    font-size: 21px;
    font-weight: 700;
    margin-left: 38px;
    margin-right: 38px;
    margin-bottom: 10px;

    @include mobile {
      margin-left: unset;
      margin-right: unset;
      font-size: 24px;
      text-align: center;
      font-size: 21px;
      text-align: left;
      padding-left: 38px;
    }

    @include mobileLandscape() {
      margin-left: 64px;
      margin-right: 64px;
      font-size: 24px;
      text-align: center;
      padding-left: unset;
    }

    @include tablet() {
      margin-left: 84px;
      margin-right: 84px;
      font-size: 28px;
      padding-left: unset;
      text-align: unset;
    }

    @include laptop() {
      font-size: 30px;
      padding-left: unset;
      text-align: center;
    }

    @include desktop {
      font-size: 36px;
      padding-left: unset;
      text-align: center;
      min-height: 114px;
      padding-bottom: unset;
    }
  }

  p {
    font-size: 16px;
    font-weight: 300;
    margin-left: 38px;
    margin-right: 38px;
    color: var(--color-mid-charcoal);

    @include mobileLandscape() {
      margin-left: 64px;
      margin-right: 64px;
      font-size: 17px;
    }

    @include tablet() {
      margin-left: 84px;
      margin-right: 84px;
      font-size: 20px;
    }

    @include laptop() {
      margin-right: unset;
      margin-left: unset;
      font-size: 22px;
    }
  }
}
.supporting-wrapper {
  @include desktop() {
    display: grid;
    grid-template-columns: 3fr 2fr;
    gap: 0;
    padding-top: 50px;
    padding-bottom: 50px;

    .supporting-local-image {
      clip-path: polygon(
        0 20px,
        100% 0,
        100% calc(100% - 40px),
        0% calc(100% - 20px)
      ) !important;
    }
  }

  .supporting-local {
    clip-path: polygon(0 20px, 100% 0, 100% calc(100% - 20px), 0% 100%);
    padding-top: 40px;
    padding-bottom: 40px;
    background: linear-gradient(
      135deg,
      var(--color-cornflower-blue) 16.7%,
      var(--color-heather) 83.95%
    );
    color: white;

    @include mobileLandscape() {
      padding-top: 60px;
      padding-bottom: 80px;
    }

    @include tablet() {
      padding-top: 90px;
      padding-bottom: 110px;
    }

    @include laptop() {
      padding-top: 120px;
      padding-bottom: 160px;
    }

    @include desktop() {
      padding-top: 100px;
      padding-bottom: 50px;
    }

    h2 {
      margin-left: 32px;
      margin-right: 32px;
      font-size: 28px;

      @include mobileLandscape() {
        margin-left: 64px;
        margin-right: 64px;
        font-size: 22px;
      }

      @include tablet() {
        margin-left: 84px;
        margin-right: 84px;
        font-size: 24px;
      }

      @include laptop() {
        margin-left: 118px;
        margin-right: 118px;
        font-size: 40px;
      }

      @include desktop() {
        margin-left: 55px;
        margin-right: 80px;
        font-size: 48px;
      }
    }

    p {
      margin-left: 32px;
      margin-right: 32px;
      margin-top: 18px;
      margin-bottom: 50px;
      margin-block-end: 0;
      font-size: 20px;

      @include mobileLandscape() {
        margin-left: 64px;
        margin-right: 64px;
        font-size: 17px;
      }

      @include tablet() {
        margin-left: 84px;
        margin-right: 84px;
        font-size: 20px;
      }

      @include laptop() {
        margin-left: 118px;
        margin-right: 118px;
        font-size: 28px;
      }

      @include desktop() {
        margin-left: 55px;
        margin-right: 80px;
        font-size: 30px;
      }
    }
  }

  .supporting-local-image {
    width: 100%;
    height: 230px;
    background-size: cover;
    clip-path: polygon(0 20px, 100% 0, 100% calc(100% - 20px), 0% 100%);
    margin-top: -20px;

    @include mobileLandscape() {
      height: 300px;
    }

    @include tablet() {
      height: 400px;
    }

    @include laptop() {
      height: 500px;
    }

    @include desktop() {
      height: 440px;
    }
  }
}
.easy-wrapper {
  @include desktop {
    display: grid;
    grid-template-columns: 3fr 2fr;
    grid-auto-flow: dense;

    .get-started {
      grid-column: 2;
    }

    .video-wrapper {
      margin-top: 50px;
    }
  }

  .localo-is-easy {
    grid-column: 2;
    margin-top: 30px;
    margin-left: 32px;
    margin-right: 32px;

    @include mobileLandscape() {
      margin-left: 64px;
      margin-right: 64px;
    }

    @include tablet() {
      margin-left: 84px;
      margin-right: 84px;
    }

    @include laptop() {
      margin-left: 110px;
      margin-right: 110px;
    }

    @include desktop() {
      margin-left: 50px;
      margin-right: 50px;
    }

    h2 {
      color: var(--color-raspberry-sorbet);
      font-weight: 700;
      font-size: 28px;

      @include mobileLandscape() {
        font-size: 24px;
      }

      @include tablet() {
        font-size: 28px;
      }

      @include laptop() {
        font-size: 40px;
      }
    }

    h3 {
      font-weight: 300;
      font-size: 20px;
      margin-top: 12px;
      color: var(--color-raspberry-sorbet);

      @include mobileLandscape() {
        font-size: 17px;
      }

      @include tablet() {
        font-size: 23px;
      }

      @include laptop() {
        font-size: 26px;
      }
    }

    p {
      margin-top: 10px;
      color: var(--color-mid-charcoal);
      font-size: 16px;
      margin-bottom: 40px;

      @include mobileLandscape() {
        font-size: 17px;
        margin-top: 30px;
      }

      @include tablet() {
        font-size: 20px;
        margin-top: 16px;
      }

      @include laptop() {
        font-size: 22px;
        margin-top: 30px;
      }
    }
  }

  .video-wrapper {
    border: 5px solid #ffffff;

    @include laptop() {
      margin-left: 110px;
      margin-right: 110px;
    }

    @include desktop {
      margin-left: 50px;
      margin-right: 50px;
    }

    .video-16-9 {
      padding-top: 56.25%;
      height: 0;
      position: relative;

      box-sizing: border-box;

      .video {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: var(--color-raspberry-sorbet);
        margin-bottom: 30px;
      }

      iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: 0;
      }
    }
  }

  .get-started {
    background-color: var(--color-raspberry-sorbet);
    padding: 15px 50px;
    display: block;
    text-align: center;
    width: fit-content;
    margin: auto;
    color: #FFFFFF;
    font-size: 18px;
    margin-top: 20px;
    margin-bottom: 55px;

    @include tablet {
      margin-top: 45px;
      margin-bottom: 65px;
    }

    @include laptop {
      margin-top: 60px;
      margin-bottom: 75px;
    }

    @include desktop {
      margin-top: 0;
      margin-bottom: 100px;
    }
  }
}
