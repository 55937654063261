@import '../../scss/mixins';

.contact-form {
  @include mobile {
    max-width: 75%;
    margin: 0 auto;
    padding-top: 25px;
    padding-bottom: 55px;
    display: flex;
    flex-direction: column;
    gap: 2em;
  }

  @include mobileLandscape {
    max-width: 75%;
    margin: 0 auto;
    padding-top: 25px;
    padding-bottom: 55px;
  }

  @include tablet {
    max-width: 98%;
    margin: 0 auto;
    padding-top: 25px;
    padding-bottom: 55px;
  }

  @include laptop {
    max-width: 820px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding-top: 65px;
    padding-bottom: 175px;
    &:after {
      content: '';
      background-image: url('../../assets/leaf-green.png');
      height: 250px;
      width: 250px;
      background-repeat: no-repeat;
      background-size: contain;
      display: inline-block;
      position: absolute;
      left: -100px;
      bottom: -120px;
      z-index: -1;
    }
  }

  @include desktop {
    max-width: 1000px;
    margin: 0 auto;
    padding-top: 65px;
    padding-bottom: 175px;
    position: relative;
    &:after {
      content: '';
      background-image: url('../../assets/leaf-green.png');
      height: 291px;
      width: 296px;
      background-repeat: no-repeat;
      background-size: contain;
      display: inline-block;
      position: absolute;
      left: -300px;
      bottom: -120px;
      z-index: -1;
    }
  }

  margin-top: 1em;
  margin-bottom: 1em;

  .left {
    .form {
      display: flex;
      flex-direction: column;
      position: relative;
      max-width: 100%;

      gap: 1em;

      > div {
        display: flex;
        flex-direction: column;
        justify-content: center;

        &:last-child {
          text-align: center;
          align-items: center;
        }

        input,
        textarea {
          max-width: 100%;
          padding: 16px 27px;
          font-size: 18px;
          border: 3px solid var(--color-mint-green);
          font-family: 'Quicksand', sans-serif;
          font-weight: normal;
          @include mobile {
            padding: 11px 12px;
          }
          @include mobileLandscape {
            padding: 11px 12px;
          }
          @include tablet {
            padding: 16px 27px;
          }
          @include laptop {
            padding: 16px 27px;
          }
          @include desktop {
            padding: 16px 27px;
          }
        }

        button {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          width: 92px;
          padding: 10px 20px;
          @include mobile {
            width: 146px;
            padding: 10px 20px;
          }
          @include mobileLandscape {
            width: 146px;
            padding: 10px 20px;
          }
          @include tablet {
            width: 146px;
            padding: 10px 20px;
          }
          @include laptop {
            width: 92px;
            padding: 10px 20px;
          }
          @include desktop {
            width: 92px;
            padding: 10px 20px;
          }
        }
      }
    }
  }

  .right {
    display: flex;
    flex-direction: column;

    gap: 1.5em;

    @include mobile {
      text-align: center;
    }

    @include mobileLandscape {
      text-align: center;
    }

    @include tablet {
      width: 80%;
      margin: 0 auto;
      text-align: unset;
    }

    @include laptop {
      width: unset;
      text-align: unset;
    }

    @include desktop {
      width: unset;
      text-align: unset;
    }

    div {
      &:last-of-type {
        p {
          &:last-child {
            color: #8c8c8c;
            margin-top: 5px;
          }
        }
      }
    }

    address {
      display: flex;
      flex-direction: row;
      font-style: unset;
      color: #8c8c8c;
      line-height: 22px;
      letter-spacing: -0.01em;
      margin-top: 5px;
      @include mobile {
        text-align: center;
      }
      @include mobileLandscape {
        text-align: center;
      }
      @include tablet {
        text-align: unset;
      }
      @include laptop {
        text-align: unset;
      }
      @include desktop {
        text-align: unset;
      }

      br {
        display: none;
      }
    }

    .social {
      display: flex;
      flex-direction: column;
      gap: 1em;

      p {
        letter-spacing: -0.03em;
        @include mobile {
          text-align: center;
        }
        @include mobileLandscape {
          text-align: center;
        }
        @include tablet {
          text-align: unset;
        }
        @include laptop {
          text-align: unset;
        }
        @include desktop {
          text-align: unset;
        }
      }

      .item {
        display: flex;
        flex-direction: row;
        gap: 5px;
        align-items: center;
        font-size: 18px;
        color: #8c8c8c;

        @include mobile {
          width: 150px;
          margin-left: auto;
          margin-right: auto;
        }

        @include tablet {
          width: auto;
          margin-left: 10px;
          margin-right: 0;
        }

        svg {
          color: var(--color-mid-charcoal);
          font-size: 2em;
          margin-right: 11px;
          width: 38px;
        }
      }
    }

    .purple-heading {
      font-size: 24px;

      @include desktop {
        font-size: 28px;
      }
    }
  }

  .error {
    margin-top: 5px;
  }
}
