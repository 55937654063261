.petals {
    background-image: url('../assets/leaf-green.png');
    width: 121px;
    height: 121px;
    background-size: cover;
    background-repeat: no-repeat;
    position: absolute;

    &.top.left {
        transform: rotate(90deg);
        top: -20px;
        left: -20px;
    }

    &.top.right {
        transform: rotate(180deg);
        top: -20px;
        right: -20px;
    }

    &.bottom.left {
        bottom: -20px;
        left: -20px;
    }

    &.bottom.right {
        transform: rotate(270deg);
        bottom: -20px;
        right: -20px;
    }
}
