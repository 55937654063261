@import "../../scss/mixins";

.shop-grid {
  display: grid;
  max-width: 96%;
  margin: 0 auto;
  margin-top: 20px;
  margin-bottom: 25px;

  @include mobile {
    grid-template-columns: repeat(2, 1fr);
    gap: 6px;
  }

  @include mobileLandscape {
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
    margin-top: 20px;
  }

  @include tablet {
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
  }
  
  @include laptop {
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;
    margin-bottom: 75px;
    max-width: 100%;
  }

  @include desktop {
    grid-template-columns: repeat(6, 1fr);
    gap: 30px;
    margin-top: 40px;
  }

  justify-content: center;
  // align-items: center;
  a {
    max-width: 100%;
    @include mobile {
      &:nth-of-type(even) {
        width: max-content;
      }
      &:nth-of-type(odd) {
        width: max-content;
      }
    }
    @include mobileLandscape {
      &:nth-of-type(even) {
        width: max-content;
      }
      &:nth-of-type(odd) {
        width: max-content;
      }
    }
    @include tablet {
      &:nth-of-type(even) {
        width: max-content;
      }
      &:nth-of-type(odd) {
        width: max-content;
      }
    }
    @include laptop {
      &:nth-of-type(even) {
        margin-left: 0;
        width: max-content;
      }
      &:nth-of-type(odd) {
        margin-left: 0px;
        width: max-content;
      }
    }
    @include desktop {
      &:nth-of-type(even) {
        margin-left: 0;
        width: max-content;
      }
      &:nth-of-type(odd) {
        margin-left: 0px;
        width: max-content;
      }
    }
  }
}

.shop-grid-item {
  display: flex;

  flex-direction: column;

  cursor: pointer;
  @include laptop {
    min-height: 356px;
    max-width: 208px;
  }
  @include desktop {
    min-height: 356px;
    max-width: 208px;
  }

  &-header {
    @include mobile {
      height: 139px;
      width: 168px;
    }

    @include mobileLandscape {
      height: 100px;
      width: 210px;
    }

    @include tablet {
      height: 142px;
      width: 281px;
    }

    @include laptop {
      height: 208px;
      width: 220px;
    }

    @include desktop {
      height: 208px;
      width: 208px;
    }
    
    > img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    
    > p {
      font-weight: 700;
      margin: 0;
      color: #000;
      margin-top: 8px;
      @include mobile {
        font-size: 15px;
      }

      @include mobileLandscape {
        font-size: 15px;
      }

      @include tablet {
        font-size: 20px;
        max-width: 100%;
      }
    }
  }

  &-meta {
    p:last-of-type {
      text-overflow: ellipsis;
    }
    margin-top: 8px;
    font-weight: 700;
    color: #000000;
    @include mobile {
        font-size: 17px;
        line-height: 21px;
        margin-bottom: 10px;
    }
    @include mobileLandscape {
        font-size: 15px;
        line-height: 19px;
        margin-bottom: 4px;
    }
    @include tablet {
        font-size: 20px;
        line-height: 25px;
        margin-bottom: 10px;
    }
  }

  &-meta-address {
    margin-bottom: 16px;
    @include laptop {
      margin-bottom: 0;
    }
    p {
      font-weight: 500;
      margin: 0;
      padding: 0;
      color: #8c8c8c;

      & + p {
        margin-top: 2px;
      }

      @include mobile {
        font-size: 13px;
      }

      @include mobileLandscape {
        font-size: 13px;
      }

      @include tablet {
        font-size: 18px;
        line-height: 20px;
      }
    }
  }
}

.category-header {
  font-weight: bold;
  color: #000000;
  padding-top: 10px;
  font-size: 21px;
  line-height: 26px;
  
  @include tablet {
    font-size: 28px;
    line-height: 45px;
  }
}

.category-card {
  background: #ffffff;
  box-sizing: border-box;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.15);
  border-radius: 2px;
  position: relative;

  &:hover {
    cursor: pointer;
  }

  &__img {
    position: relative;
    overflow: hidden;
    padding-top: 56.25%;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      position: absolute;
      top: 0;
    }
  }

  &__content {
    padding: 10px 15px 15px 15px;
  }

  &__title {
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    @include tablet {
      font-size: 17px;
      line-height: 21px;
    }
  }

  &__subText {
    color: #8c8c8c;
    font-weight: 500;
    margin-top: 4px;
    font-size: 12px;
    line-height: 15px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    @include tablet {
      font-size: 13px;
      line-height: 20px;
    }
  }
}