@import '../../scss/mixins';

.join-localo-contact {
    color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    position: relative;
    z-index: 2;

    &--questions {
        background: linear-gradient(135deg, #FF005E 16.7%, #DEC400 83.95%);
        padding: 50px 0;

        @include laptop() {
            padding: 100px 0 80px;
        }
    }

    &--selling {
        background-image: url('../../assets/join-localo/selling-hero.png');
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        padding: 80px 0 ;

        @include laptop() {
            padding: 200px 0 140px;
            background-attachment: fixed;
        }
        
        &::after {
            background-color: #000000;
            position: absolute;
            content: '';
            top: 0;
            right: 0;
            left: 0;
            bottom: 0;
            opacity: 0.45;
            z-index: -1;
        }
    }

    &__title {
        font-weight: bold;
        font-size: 21px;
        line-height: 26px;
        letter-spacing: -0.01em;
        margin-bottom: 20px;

        @include laptop() {
            font-size: 40px;
            line-height: 50px;
        }

        @include desktop() {
            font-size: 48px;
            line-height: 60px;
        }
    }

    &__desc {
        font-weight: 300;
        font-size: 16px;
        line-height: 20px;
        letter-spacing: -0.01em;
        margin-bottom: 40px;
        max-width: 285px;
        margin-left: auto;
        margin-right: auto;
        
        @include laptop() {
            font-size: 26px;
            line-height: 32px;
            max-width: unset;
        }

        @include desktop() {
            font-size: 30px;
            line-height: 37px;
        }
    }

    &__button {
        height: 50px;
        padding: 0 20px;
        font-size: 18px;
        margin: 0;
        font-family: inherit;
        display: block;
        font-weight: bold;
        text-align: center;
        cursor: pointer;
        border: 3px solid transparent;
        border-radius: 0;
        transition: all .15s ease-in-out;
        margin: 0 auto;

        &--green {
            background-color: var(--color-mint-green);
            color: #FFFFFF;   
            min-width: 200px;
            
            &:hover {
                color: var(--color-mint-green);
                border-color: var(--color-mint-green);
                background-color: #FFFFFF;
            }

            @include desktop() {
                min-width: 340px;
                margin-top: 0;
            }
        }

        &--pink {
            background-color: var(--color-raspberry-sorbet);
            color: #FFFFFF;
            min-width: 295px;
            
            &:hover {
                color: var(--color-raspberry-sorbet);
                border-color: var(--color-raspberry-sorbet);
                background-color: #FFFFFF;
            }

            @include desktop() {
                min-width: 650px;
                margin-top: 20px;
            }
        }

        @include laptop() {
            height: 60px;
            padding: 0 30px;
            font-size: 24px;
        }
        
        @include desktop() {
            height: 100px;
            font-size: 32px;
            padding: 0 50px;
        }
    }
}