@import "../../scss/mixins";
.itisonPage {
  display: flex;
  justify-content: center;
  text-align: center;
  flex-direction: column;
  .itison-form {
    display: flex;
    padding: 0px;
    input[type="submit"] {
      height: 54px;
      margin-left: 20px;
    }
    @include mobileLandscape-down {
      padding: 0px 10px;
      input[type="submit"] {
        margin-left: 0px;
      }
    }
  }
  .border-box {
    margin-bottom: 30px;
    border: 0.5px solid #e2e2e2;
    max-width: 680px;
    margin: 0 auto 30px;
    width: 100%;
    padding-bottom: 60px;
    min-width: 1px;
    .container-component {
      max-width: 100%;
      min-width: 1px;
      padding: 0;
    }
    .heading {
      background-color: #ed2273;
    }
    .signup-text {
      font-size: 28px;
      font-weight: bold;
      margin-bottom: 35px;
      color: #ed2273;
    }
    .signup-sub-text {
      font-size: 20px;
      font-weight: bold;
      color: #ed2273;
    }
  }
}
.register-with-details {
  margin-bottom: 30px;
  border: 0.5px solid #e2e2e2;
  max-width: 680px;
  margin: 0 auto 30px;
  width: 100%;
  padding-bottom: 60px;
}
.welcome-text {
  font-size: 28px;
  font-weight: bold;
  margin-top: 44px;
  margin-bottom: 37px;
  color: #ed2273;
}
.thank-you-text {
  font-size: 17px;
  font-weight: bold;
  margin-bottom: 50px;
  color: #8c8c8c;
  max-width: 445px;
  margin-left: auto;
  margin-right: auto;
}
.sub-text {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 16px;
  color: #ed2273;
  max-width: 445px;
  margin-left: auto;
  margin-right: auto;
  &.cursor-pointer {
    cursor: pointer;
  }
  &.in-correct-code {
    margin-top: 30px;
  }
}

.itison-product-detaile {
  width: 250px;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: auto;
  margin-right: auto;
  text-align: initial;
  .product-card__content {
    text-align: center;
  }
}
