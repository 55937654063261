@import "../../scss/mixins";

html {
  scroll-behavior: smooth;
}

.legal-page {
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  padding: 0 20px;

  @include laptop {
    display: grid;
    grid-template-columns: 1fr 3fr;
    margin-top: 60px;
    margin-bottom: 100px;
    padding: 0;
  }

  &-left {
    display: flex;
    flex-direction: column;

    a {
      background-color: var(--color-mint-green);
      font-size: 15px;
      font-weight: 700;
      color: white;
      padding: 10px 0;
      text-align: center;
      margin-bottom: 5px;
      @include tablet {
        padding: 3px 10px;
        font-size: 22px;
      }
      @include laptop {
        background-color: unset;
        font-size: 16px;
        font-weight: 700;
        color: var(--color-mint-green);
        margin-bottom: 5px;
        text-align: unset;
      }
    }
  }

  &-links {
    position: sticky;
    top: 25px;
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;
    
    @include laptop {
      padding-right: 35px;
      align-items: flex-start;
    }
  }

  &-right {
    display: grid;
    gap: 15px;
    color: #8C8C8C;
    font-style: normal;
    font-weight: 300;
    font-size: 13px;
    line-height: 16px;
    letter-spacing: -0.01em;
    max-width: 800px;

    @include laptop {
      font-size: 17px;
      line-height: 21px;
    }

    @include desktop {
      font-size: 18px;
      line-height: 22px;
    }
    
    &-title {
      color: var(--color-raspberry-sorbet);
      font-size: 15px;
      line-height: 21px;
      font-weight: 700;
      line-height: normal;

      @include laptop {
        font-size: 30px;
        list-style: 37px;
      }

      @include desktop {
        font-size: 36px;
        list-style: 45px;
      }
    }
    &-subtitle {
      color: var(--color-raspberry-sorbet);
      font-size: 17px;
      
      @include laptop {
        margin: 10px 0 15px;
        font-size: 21px;
      }
    }
  }

  &-markdown {
    &:not(.active) {
      display: none;
    }

    @include desktop {
      &:not(.active) {
        display: block;
      }
    }
    h1 {
      color: var(--color-mint-green);
      line-height: normal;
      padding-bottom: 10px;
      padding-top: 10px;
      font-size: 18px;

      @include tablet {
        font-size: 23px;
      }

      @include laptop {
        padding-bottom: 15px;
        padding-top: 20px;
      }

      @include desktop {
        font-size: 26px;
      }
    }
    p {
      margin-bottom: 10px;

      @include laptop {
        margin-bottom: 15px;
      }
    }
    a {
      color: #FF005E;
      color: var(--color-raspberry-sorbet);
    }
    strong {
      font-weight: 600;
    }
    ul, ol {
      margin-top: 0;
      margin-bottom: 10px;
      padding-left: 20px;

      ul, ol {
        margin-bottom: 2px;
        margin-top: 2px;
      }

      @include laptop {
        margin-bottom: 15px;
      }
    }
  }
}
