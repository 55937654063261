@import '../../scss/mixins';

.join-localo-start-selling {
    padding: 65px 0 90px;
    position: relative;

    @include desktop() {
        padding: 90px 0 170px;
    }

    &__wrapper {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        @include laptop() {
            flex-direction: row;
        }
    }
    
    &__title {
        color: var(--color-mint-green);
        font-size: 45px;
        line-height: 45px;
        font-weight: bold;
        letter-spacing: -0.03em;
        text-align: center;
        margin: 0;

        @include laptop() {
            font-size: 55px;
            line-height: 69px;
            margin-left: 200px;
        }
        
        @include desktop() {
            font-size: 85px;
            line-height: 106px;
            margin-left: 0;
        }
    }

    &__button {
        height: 50px;
        padding: 0 50px;
        background-color: var(--color-raspberry-sorbet);
        color: #FFFFFF;
        font-size: 18px;
        margin: 0;
        font-family: inherit;
        display: block;
        font-weight: bold;
        text-align: center;
        cursor: pointer;
        border: 3px solid transparent;
        border-radius: 0;
        transition: all .15s ease-in-out;
        margin: 30px auto 0;

        &:hover {
            color: var(--color-raspberry-sorbet);
            border-color: var(--color-raspberry-sorbet);
            background-color: #FFFFFF;
        }

        @include laptop() {
            height: 60px;
            padding: 0 20px;
            font-size: 24px;
            margin: 0 0 0 40px;
        }
        
        @include desktop() {
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            margin: 0;
            height: 100px;
            font-size: 32px;
            padding: 0 50px;
        }
    }

    .selling-leaf-big {
        background-image: url('../../assets/join-localo/selling-leaf-big.svg');
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        position: absolute;
        top: auto;
        bottom: 0;
        left: -25px;
        width: 130px;
        height: 131px;
        pointer-events: none;
        z-index: 1;

        @include laptop() {
            left: 20px;
            background-size: auto;
            top: -100px;
            bottom: auto;
            width: 240px;
            height: 243px;
        }

        @include desktop() {
            left: 75px;
        }
    }

    .selling-leaf-small {
        background-image: url('../../assets/join-localo/selling-leaf-small.svg');
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        position: absolute;
        bottom: -40px;
        left: 80px;
        width: 72px;
        height: 72px;
        pointer-events: none;
        z-index: 1;

        @include laptop() {
            left: 150px;
            bottom: -110px;
            background-size: auto;
            width: 168px;
            height: 170px;
        }

        @include desktop() {
            bottom: -40px;
            left: 250px;
        }
    }
}