@import './mixins';

@mixin standard-bottom-border {
  
  border-bottom: 1px solid rgb(212, 212, 212);
}

@mixin standard-spacing($gap: 1) {
  display: flex;
  flex-direction: column;
  gap: #{$gap}em;
}

@mixin remove-text-styling {
  a {text-decoration: underline solid #39a6ff; color: black;}
}

.highstreet {
  @include standard-spacing();
  @include remove-text-styling();
  flex: 0 0 100%;
  max-width: 100%;
  box-sizing: border-box;
  padding: 0 8px;
  margin-bottom: 25px;

  @include tablet {
    flex: 0 0 50%;
    max-width: 50%;
    margin-bottom: 30px;
  }

  @include laptop {
    flex: 0 0 33.33%;
    max-width: 33.33%;
    margin-bottom: 40px;
  }

  .vendor {
    @include standard-spacing(1);
    @include remove-text-styling();
  .product {
      @include remove-text-styling();
}
  }
}

.sitemap-wrapper {
  display: flex;
  flex-wrap: wrap;
  margin: 35px -8px 70px;

  .vendor,
  .product {
    padding-left: 1em;
    position: relative;

    &::before {
      position: absolute;
      content: '';
      width: 8px;
      height: 8px;
      background-color: black;
      left: 2px;
      border-radius: 100%;
      top: 6px;
    }
  }

  .variant {
    padding-left: 1em;

    h5 {
      margin-top: 3px;
      margin-bottom: 3px;
    }
  }
}