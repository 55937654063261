@import '../../scss/mixins';

.shop-grid {
    display: grid;

    margin-top: 25px;
    margin-bottom: 75px;

    @include mobile {
        grid-template-columns: repeat(2, 1fr);
        gap: 6px;
    }

    @include mobileLandscape {
        grid-template-columns: repeat(2, 1fr);
        gap: 10px;
    }

    @include tablet {
        grid-template-columns: repeat(2, 1fr);
        gap: 15px;
    }

    @include laptop {
        grid-template-columns: repeat(4, 1fr);
        gap: 20px;
    }

    @include desktop {
        grid-template-columns: repeat(6, 1fr);
        gap: 30px;
    }

    justify-content: center;
    // align-items: center;

}