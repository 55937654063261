@import '../../scss/mixins';

.join-localo-what-sell {
    padding: 90px 0;
    color: #FFFFFF;
    background: linear-gradient(135deg, #9CB6FF 16.7%, #B690C5 83.95%);
    clip-path: polygon(0% 30px, 100% 0%, 100% 90%, 0% 100%);
    position: relative;
    text-align: center;
    
    @include laptop() {
        padding: 160px 0;
        clip-path: polygon(0% 90px, 100% 0%, 100% 80%, 0% 100%);
    }
    
    &__title {
        font-size: 21px;
        line-height: 26px;
        font-weight: bold;
        margin-bottom: 25px;
        
        @include laptop() {
            font-size: 30px;
            line-height: 37px;
        }
        
        @include desktop() {
            margin: 0 0 35px;
            font-size: 48px;
            line-height: 60px;
        }
    }
    
    &__desc {
        margin: 0 auto;
        font-size: 16px;
        line-height: 20px;
        font-weight: 300;
        letter-spacing: -0.01em;
        padding: 0 32px;
        
        @include tablet() {
            max-width: 640px;
            font-size: 20px;
            line-height: 25px;
        }
        
        @include desktop() {
            max-width: 960px;
            font-size: 25px;
            line-height: 31px;
            padding: 0;
        }
    }
}