@import '../../scss/mixins';

.join-localo-boost-sells {
    background-image: url('../../assets/join-localo/boost-sell-hero.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    color: #FFFFFF;
    padding: 50px 0 70px;
    position: relative;

    @include mobileLandscape() {
        padding: 90px 0;
    }

    @include laptop() {
        background-attachment: fixed;
        padding: 230px 0 200px;
    }

    &::after {
        background-color: #000000;
        position: absolute;
        content: '';
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        opacity: 0.45;
    }

    .container-component {
        position: relative;
        z-index: 2;
    }

    &__title {
        font-weight: bold;
        font-size: 21px;
        line-height: 26px;
        text-align: center;
        letter-spacing: -0.01em;
        margin-bottom: 20px;
        
        @include mobileLandscape() {
            font-size: 24px;
            line-height: 30px;
            margin-bottom: 30px;
        }

        @include laptop() {
            font-size: 40px;
            line-height: 50px;
        }

        @include desktop() {
            font-size: 48px;
            line-height: 60px;
        }
    }

    &__desc {
        font-weight: 300;
        font-size: 20px;
        line-height: 25px;
        letter-spacing: -0.01em;
        margin: 0 auto;
        max-width: 320px;
        padding: 0 35px;

        @include mobileLandscape() {
            font-size: 17px;
            line-height: 21px;
            padding: 0;
        }

        @include laptop() {
            font-size: 28px;
            line-height: 36px;
            max-width: 780px;
        }

        @include desktop() {
            font-size: 30px;
            line-height: 37px;
            max-width: 1140px;
        }
    }
}