@import '../../scss/mixins';

.product-grid {
    max-width: 96%;
    margin: 0 auto;
    margin-top: 25px;
    margin-bottom: 75px;
    a {
        color: #000;
    }
    @include mobile {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 6px;
    }

    @include mobileLandscape {
        gap: 10px;
    }

    @include tablet {
        gap: 10px;
    }

    @include laptop {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 20px;
        max-width: 100%;
    }

    @include desktop {
        display: grid;
        grid-template-columns: repeat(6, 1fr);
        gap: 30px;
        margin-top: 40px;
    }
    &__loader {
      border: 3px solid #f3f3f3;
      border-top: 3px solid var(--color-raspberry-sorbet);
      border-radius: 50%;
      width: 30px;
      height: 30px;
      animation: spin 1.5s linear infinite;
    }
  
    @keyframes spin {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
}