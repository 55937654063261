$colors: (
    raspberry-sorbet: #FF005E,
    american-mustard: #DEC400,
    mint-green: #8DDC73,
    mid-charcoal: #8C8C8C,
    cornflower-blue: #9CB6FF,
    purpleish: #B690C5,
    heather: #B690C5,
    grey: #999999,
    white: #FFFFFF, 
    error: #FF9494
);

:root {
    @each $key, $color in $colors {
        --color-#{$key}: #{$color};
    }
}

@each $name, $color in $colors {
    .color-#{$name} {
        color: $color;
    }

    .color-#{$name}-outline {
        color: $color;
        border: 1px solid $color !important;
        background-color: #fff;
    }

    .background-#{$name} {
        background-color: $color;
    }
}