@import '../../../scss/mixins';

.col-2 {
    display: flex;
    flex-direction: column;
    gap: 10px;

    @include tablet {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 10px;
    }
}

.custom-height {
    max-height: 540px;
    position: fixed;
    inset: 25% 0px 0px;
    max-width: 600px;
    left: 0;
    right: 0;
    margin: 0 auto;
    top: 10%;
    @include tablet {
        max-height: 370px;
    }
}