@import '../../scss/mixins';

.product-grid-item {
    @include laptop {
        &:hover {
            transition: transform 500ms ease-in-out;
            transform: translateY(7px);
        }
    }
    &-image {
        @include mobile {
            width: 168px;
            height: 139px;
        }
        @include mobileLandscape {
            width: 210px;
            height: 99px;
        }
        @include tablet {
            width: 281px;
            height: 142px;
        }
        @include laptop {
            width: 220px;
            height: 208px;
        }
        @include desktop {
            width: 208px;
            height: 208px;
        }
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    &-meta-title {
        margin-top: 8px;
        font-weight: 700;
        color: #000000;
        @include mobile {
            font-size: 17px;
            line-height: 21px;
            margin-bottom: 10px;
        }
        @include mobileLandscape {
            font-size: 15px;
            line-height: 19px;
            margin-bottom: 4px;
        }
        @include tablet {
            font-size: 20px;
            line-height: 25px;
            margin-bottom: 10px;
        }
    }

    &-meta-address {
        margin-bottom: 16px;
        @include laptop {
          margin-bottom: 0;
        }
        p {
          font-weight: 500;
          margin: 0;
          padding: 0;
          color: #8c8c8c;
    
          & + p {
            margin-top: 2px;
          }
    
          @include mobile {
            font-size: 13px;
          }
    
          @include mobileLandscape {
            font-size: 13px;
          }
    
          @include tablet {
            font-size: 18px;
            line-height: 20px;
          }
        }
    }
}