@import '../../scss/mixins';

.join-localo-help {
    padding: 90px 0;
    color: #FFFFFF;
    background: linear-gradient(135deg, #FF005E 16.7%, #9CB6FF 83.95%);
    clip-path: polygon(0% 0%, 100% 0%, 100% 95%, 0% 100%);
    position: relative;
    
    @include laptop() {
        padding: 190px 0;
        clip-path: polygon(0% 0%, 100% 0%, 100% 90%, 0% 100%);
    }

    &::before {
        background-image: url('../../assets/join-localo/help-section-leaf.png');
        background-repeat: no-repeat;
        position: absolute;
        content: '';
        width: 115px;
        height: 104px;
        top: 0;
        left: 0;
        pointer-events: none;
        background-size: contain;  
        
        @include tablet() {
            width: 164px;
            height: 99px;
        }
        
        @include laptop() {
            width: 235px;
            height: 224px;
        }

        @include desktop() {
            width: 335px;
            height: 324px;
        }
    }
    
    &__header {
        text-align: center;
        margin-bottom: 50px;

        @include laptop() {
            margin-bottom: 90px;
        }
    
        &__title {
            font-weight: bold;
            font-size: 21px;
            line-height: 26px;
            letter-spacing: -0.01em;
            margin-bottom: 25px;

            @include laptop() {
                font-size: 40px;
                line-height: 50px;
            }

            @include desktop() {
                font-size: 48px;
                line-height: 60px;
            }
        }

        &__desc {
            font-weight: 300;
            font-size: 16px;
            line-height: 20px;
            letter-spacing: -0.01em;
            margin: 0 auto;
            max-width: 285px;
            
            @include laptop() {
                max-width: 775px;
                font-size: 28px;
                line-height: 35px;
            }
            
            @include desktop() {
                max-width: 950px;
                font-size: 30px;
                line-height: 37px;
            }
        }
    }

    &__list {
        display: flex;
        flex-direction: column;
        align-items: center;
        
        @include laptop() {
            margin: 0 -20px;
            flex-direction: row;
        }
    }

    &__item {
        padding-left: 120px;
        box-sizing: border-box;
        position: relative;
        max-width: 330px;

        & + & {
            margin-top: 40px;
        }

        @include laptop() {
            flex: 0 0 33.33%;
            max-width: 33.33%;
            padding-left: 20px;
            padding-right: 20px;

            & + & {
                margin-top: 0;
            }
        }
    }

    &__icon {
        background-color: var(--color-mint-green);
        width: 100px;
        height: 100px;
        border-radius: 100%;
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);

        img {
            max-width: 63px;
        }

        @include laptop() {
            width: 133px;
            height: 133px;
            position: static;
            top: 0;
            transform: none;

            img {
                max-width: 73px;
            }
        }
    }

    &__title {
        font-size: 17px;
        line-height: 21px;
        font-weight: bold;
        margin: 0 0 10px 0;
        
        @include laptop() {
            font-size: 24px;
            line-height: 30px;
            margin: 30px 0 20px;
            text-align: center;
        }

        @include desktop() {
            font-size: 36px;
            line-height: 45px;
            margin: 50px 0 20px;
        }
    }

    &__desc {
        font-size: 14px;
        line-height: 17px;
        font-weight: 300;
        letter-spacing: -0.01em;

        @include laptop() {
            font-size: 20px;
            line-height: 25px;
        }
    
        @include desktop() {
            font-size: 28px;
            line-height: 35px;
        }
    }
}