@import "../../scss/mixins";

.shop-card {
  background: #ffffff;
  box-sizing: border-box;
  position: relative;
  overflow: hidden;

  @include mobileLandscape-down {
    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.15);
    border-radius: 2px;
  }

  &:hover {
    cursor: pointer;
  }

  &__badge {
    background-color: var(--color-raspberry-sorbet);
    position: absolute;
    left: 5px;
    top: 5px;
    z-index: 2;
    width: 51px;
    height: 51px;
    line-height: 15px;
    text-align: center;
    font-weight: bold;
    font-size: 15px;
    border-radius: 100%;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: rotate(-10.27deg);
  }

  &__img {
    height: 145px;
    position: relative;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    @include tablet {
      height: 147px;
    }

    @include desktop {
      height: 208px;
    }
  }

  &__content {
    @include mobileLandscape-down {
      padding: 0 8px 5px;
    }
  }

  &__title {
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    margin-top: 5px;

    @include mobileLandscape {
      margin-top: 8px;
      margin-bottom: 0;
      font-size: 20px;
      line-height: 25px;
    }

    @include laptop {
      margin-bottom: 8px;
    }
  }

  &__subText {
    color: #8c8c8c;
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
    @include mobileLandscape {
      margin-top: 4px;
      font-size: 18px;
      line-height: 20px;
    }
  }

  &__site {
    border: 1px solid #8c8c8c;
    height: 32px;
    width: 160px;
    display: flex;
    align-self: center;
    justify-content: center;
    text-align: center;
    color: #8c8c8c;
    font-weight: bold;
    font-size: 13px;
    line-height: 32px;
    margin-right: 10px;
  }
}
