.loader {
    background: rgba(255,255,255,0.96);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9999;

    &__icon {
        background-repeat: no-repeat;
        background-size: cover;
        background-image: url('../../assets/circle.svg');
        height: 60px;
        width: 60px;
        display: block;
        position: relative;
        animation: circleanimation 4s linear infinite;
        
        &::before {
            animation: leafanimation 4s linear infinite;
            background-image: url('../../assets/leaf.svg');
            height: 60px;
            width: 60px;
            content: '';
            position: absolute;
            background-repeat: no-repeat;
            background-size: cover;
        }
    }

    &__text {
        color: var(--color-mid-charcoal);
        font-weight: 500;
        font-size: 16px;
        margin-top: 10px;
    }
}

@keyframes leafanimation {
    0%, 100% {
        transform: rotate(0) scale(0.85);
        opacity: 0.70;
    }
    33% {
        transform: rotate(10deg) scale(1.05);
        opacity: 1;
    }
    66% {
        transform: rotate(-10deg) scale(1.05);
        opacity: 1;
    }
}

@keyframes circleanimation {
    0%, 100% {
        transform: translateY(0) rotate(0);
    }
	33% {
        transform: translateY(-2px) rotate(8deg);
    }
	66% {
        transform: translateY(-4px) rotate(-10deg);
    }
}