@import "../../scss/mixins";

.container-component {
    max-width: 100%;
    min-width: 100%;
    margin: auto;

    @include mobile {
        max-width: 360px;
        min-width: 360px;
    }

    @include mobileLandscape {
        max-width: 450px;
        min-width: 450px;
    }

    @include tablet {
        max-width: 600px;
        min-width: 600px;
    }

    @include laptop {
        max-width: 940px;
        min-width: 940px;

        &-small {
            max-width: 600px;
            min-width: 600px;
        }
    }

    @include desktop {
        max-width: 1400px;
        min-width: 1400px;

        &-small {
            max-width: 600px;
            min-width: 600px;
        }
    }
}
