@import '../../scss/mixins';

.top-component {
    @include mobileLandscape {
        margin-top: 25px;
    }
    @include mobile {
        margin-top: 25px;
    }
    @include tablet {
        margin-top: 22px;
    }
    @include laptop {
        margin-top: 75px;
    }
    @include desktop {
        margin-top: 75px;
    }
}